.my-coin-picks-list-view {
  padding-left: 0px !important;
  padding-right: 0px !important;

  .coin-image {
    float: left;
    width: 40px;
    height: 40px;
    max-width: 40px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .my-coin-picks-list-item {
    padding: 8px 4px;
    border-bottom: 1px solid #3d3d3df2;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    // border-radius: 5px;
    margin-bottom: 0px;

    .list-items-right {
      float: right;
      text-align: right;

      .coin-price {
        font-weight: 600;
        color: var(--primary-color);
      }

      .coin-pick-time p {
        font-size: 11px;
        color: var(--text-color);
      }
    }
  }

  .list-items {
    .coin-name p {
      font-weight: 600;
      line-height: normal;
    }
  }
}
