.send-email-page {
  .react-multi-email {
    background-color: transparent;
    border: 1px solid var(--primary-color);

    &.fill,
    &.focused,
    &.empty {
      background-color: transparent;
      border: 1px solid var(--primary-color);
    }

    input {
      background-color: transparent;
      color: white;
    }
    [data-tag] {
      background-color: var(--primary-color);
    }
  }
}
