.usage-report-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 100%;
  position: relative;

  @media ($mobile-media) {
    padding-top: 2rem;
  }
}
