.members-list {
  .table {
    .first-column {
      color: var(--primary-color);
    }
  }

  .search-input {
    @media screen and (max-width: 578px) {
      input {
        padding: 12px 16px;
        &::placeholder {
          font-size: 12px;
        }
      }
      .btn {
        margin-right: 16px !important;
      }
    }
    @media screen and (max-width: 400px) {
      input {
        padding: 12px;
        &::placeholder {
          font-size: 10px;
        }
      }

      .btn {
        margin-right: 12px !important;
      }
    }
  }
}
