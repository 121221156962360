.genealogy-list {
  display: flex;

  gap: 35px;
  margin-bottom: 35px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.left-col {
      max-width: 320px;
    }
  }

  .search-input {
    .btn {
      padding: 6px;
    }

    .no-wrap {
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: contain;
        object-position: center center;
      }
    }

    @media screen and (max-width: 578px) {
      input {
        padding: 12px 16px;

        &::placeholder {
          font-size: 12px;
        }
      }

      .btn {
        margin-right: 16px !important;
      }
    }

    @media screen and (max-width: 365px) {
      input {
        padding: 12px;
      }

      .btn {
        margin-right: 12px !important;
      }
    }
  }
}

.personals-tabs {
  &-header {
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-flexContainer {
      @media screen and (max-width: 578px) {
        justify-content: center;
      }

      button {
        background: black;
        border-radius: 20px 20px 0 0;
        line-height: 100%;
        padding: 22px 30px;

        @media screen and (max-width: 578px) {
          width: 50%;
        }

        &[aria-selected="true"] {
          background: linear-gradient(90deg,
              rgba(0, 0, 0, 0.04) 0%,
              rgba(255, 255, 255, 0.04) 100%),
            #141518;
        }
      }
    }
  }

  &-body {
    background: linear-gradient(45deg,
        rgba(0, 0, 0, 0.04) 0%,
        rgba(255, 255, 255, 0.04) 100%),
      #141518;
    box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 8px 8px;


    >.MuiBox-root {
      @media screen and (max-width: 578px) {
        padding: 12px;
      }
    }
  }
}

.user-accordion {
  .MuiButtonBase-root {
    border-radius: 8px;

    svg {
      stroke-width: 1px;
      stroke: #2c2e2f;
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  .summary-container {
    >div {
      width: calc(100% - 100px);
      
      @media screen and (max-width: 578px) {
        flex-direction: column;
        width: calc(100% - 20px);
      }
    }

    .toggleText {
      display: none;
    }
  }

  &-summary {
    display: flex;
    gap: 24px;

    .image {
      height: 44px;
      min-height: 44px;
      min-width: 44px;
      overflow: hidden;
      width: 44px;
      color: $primary-blue;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
      }

      svg {
        fill: none;
        stroke: $primary-blue;
        max-height: 44px;
        max-width: 44px;
      }
    }

    .info {
      display: flex;
      justify-content: center;
      gap: 6px;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      .name {
        width: 200px;

        @media screen and (max-width: 768px) {
        width: unset;
        }
      }

      p {
        font-weight: 400;
        font-size: 15px;
        opacity: 0.7;
        line-height: 100%;
        margin-bottom: 5px;

        @media screen and (max-width: 578px) {
          font-size: 12px;
        }
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        word-break: break-all;

        @media screen and (max-width: 578px) {
          font-size: 16px;
        }
      }

      .date-expiration {
        &.expiring {
          color: #ffec5b;
        }

        &.expired {
          color: #ff8080;
        }
      }

    }
  }

  &-body {
    .info {
      margin: 16px 0;
      overflow: hidden;

      @media screen and (max-width: 578px) {
        margin: 16px 0 0;
      }

      .title {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        opacity: 0.7;

        @media screen and (max-width: 578px) {
          font-size: 8px;
        }
      }

      .description {
        font-size: 16px;
        word-wrap: break-word;
        white-space: wrap;

        @media screen and (max-width: 578px) {
          font-size: 14px;
        }

        .phone-input {
          border: none;
          background-color: transparent;
          width: 100%;
        }

        .phone-input .react-tel-input {
          background-color: none;
          padding: 0px;
        }

        .phone-input.disabled {
          cursor: default;
        }

        .phone-input .react-tel-input input {
          padding: 0px;
          background-color: transparent;
          cursor: text;
          height: auto;
        }

        .phone-input .react-tel-input .flag-dropdown {
          background-color: transparent;
        }

        .phone-input .react-tel-input .flag-dropdown .selected-flag .arrow {
          background-color: transparent;
          display: none;
        }

        .react-tel-input .selected-flag {
          width: 25px;
          height: 100%;
          padding: 0;
        }
      }
    }
  }
}
