.form {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .btn {
    margin-top: 32px;
  }

  .form-group {
    position: relative;

    &.small {
      max-width: 184px;
      margin-left: auto;
      margin-right: auto;
    }

    &.help {
      margin-right: 20px;
    }

    &.default {
      display: flex;
      flex-direction: column;
    }

    &.row {
      display: flex;
      flex-direction: row;
    }

    &.row-reverse {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    .sublabel {
      @include text-background-gradient;
      margin-bottom: 17px;
      margin-top: 2px;
      font-size: 14px;
    }

    .underlabel {
      font-size: 12px;
    }

    &.hidden {
      display: none;
    }

    .label {
      text-transform: uppercase;
      font-weight: 500;

      &.no-margin {
        margin-bottom: 0;
      }

      &.yes-margin {
        margin-bottom: 19px;
      }

      label {
        font-size: 12px;
        letter-spacing: 1.2px;
        color: var(--text-color);
        transition: 0.4s color;
      }
    }

    &.focused {
      .label label {
        @include text-background-gradient;
      }
    }
  }

  &.horizontal-alignment {
    gap: 10px !important;

    @media screen and (max-width: 991px) {
      gap: 15px !important;
    }

    @media screen and (min-width: 992px) {
      gap: 4px !important;
    }

    .form-group-text {
      margin-top: 16px;

      &:first-of-type {
        margin-top: 0px;
      }
    }

    .form-group {
      margin-right: 19px;

      @media screen and (max-width: 991px) {
        flex-direction: column !important;
      }

      @media screen and (min-width: 992px) {
        flex-direction: row !important;
      }

      .label {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 991px) {
          margin-bottom: 8px !important;
          width: 100%;
        }

        @media screen and (min-width: 992px) {
          margin-bottom: 0px !important;
          width: 150px;
        }
      }

      .form-input {
        flex: 1;
      }

      .form-input-group {
        flex: auto;
        flex-direction: column;
        display: flex;
      }
    }
  }

  &.sm {
    gap: 16px;

    .btn {
      margin-top: 0px;
    }

    .form-group .label {
      margin-bottom: 8px;
    }

    &.horizontal-alignment {
      .btn {
        margin-top: 16px;
      }

      .form-group .label {
        margin-bottom: 0px;
      }

      .form-group-text {
        margin-top: 8px;

        &:first-of-type {
          margin-top: 0px;
        }
      }

      .form-group {
        flex-direction: row;
        margin-right: 8px;
      }
    }
  }

  .editor-group:has(.form-input-error) .tox-tinymce {
    border: 2px solid rgba(brown, 0.8) !important;
  }
}
