.redeem-container-button {
  display: flex;
  width: 100%;
  z-index: 5 !important;
  bottom: -10px;
  justify-content: center;
  margin-top: 15px;

  &.historical {
    bottom: unset;
    margin-top: unset;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.redeem-coin {
  &-name {
    font-size: 18px;
  }

  &-image {
    border-radius: 50%;
    overflow: hidden;
    height: 90px;
    width: 90px;
    display: block;
    margin: 0 auto;
    background: #726a5e;
    margin-bottom: 8px;

    img {
      object-fit: cover;
      object-position: center center;
      height: 100%;
      width: 100%;
    }
  }
}
