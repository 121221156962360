// Define the mixin

// #region margin
@mixin generateMarginClasses($direction) {
    @for $i from 0 through 50 {
      .#{$direction}-#{$i} {
        #{$direction}: #{$i}px;
      }
    }
  }
  
  /* Generate margin classes for both left and right */
  @include generateMarginClasses('margin-left');
  @include generateMarginClasses('margin-right');

// This is used to apply the gradient color to the text
@mixin text-background-gradient {
  color: var(--primary-color);
  background: $background-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
