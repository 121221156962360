.tree-colors-key-container {
  position: absolute;
  right: 40px;
  top: 60px;
  z-index: 1;
  
  @media screen and (max-width: 768px) {
    left: 20px;
    right: unset;
    top: 40px;
  }

  button {
    cursor: pointer;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $primary-blue;
    border: 2px solid $primary-blue;
    font-weight: 700;
    background: none;
    transition: all 300ms ease-in-out;
    height: 24px;
    width: 24px;

    &:hover {
      background-color: rgba($primary-blue, 0.284);
    }
  }

  .tree-colors-definition {
    animation: definitionEntrance 0.5s ease-out forwards;
    background: #141518;
    border-radius: 16px;
    box-shadow: 0 0 8px 12px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 180px;
    padding: 18px;
    position: absolute;
    right: 0;
    right: 10px;

    @media screen and (max-width: 768px) {
      right: unset;
      left: 10px;
    }

    .row {
      display: flex;
      gap: 8px;
      align-items: center;

      span {
        white-space: nowrap;
      }

      .color {
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 50%;

        &--red {
          background-color: #911e1e;
        }

        &--blue {
          background-color: #1d5075;
        }

        &--green {
          background-color: #639157;
        }

        &--grey {
          background-color: #cacaca;
        }

        &--yellow {
          background-color: #dbd15c;
        }
      }
    }
  }
}

@keyframes definitionEntrance {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
