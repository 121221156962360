.memberships {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 35px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.right-col {
      max-width: 400px;
    }
  }

  .table {
    max-width: 100%;
  }
}

.transfers-filter-select {
  border: none;
  margin-left: 10px;
  min-width: 164px;

  .select {
    border-radius: 8px;
    background-color: #121316;
    transition: 0.4s background;
    border: none;

    fieldset {
      transition: 0.4s border-color;
      border-width: 1px !important;
    }

    .clean {
      background-color: #121316;
    }

    &.focused {
      background-color: transparent;
    }

    &.error {
      border-color: brown !important;
      border-style: solid;
      border-width: 1px;
    }

    &.sm {
      height: 48px !important;
      padding: 0;
    }

    .MuiSelect-select {
      color: rgb(134, 134, 134);
      padding: 17.25px 29px;
      font-size: 16px;
    }

    svg {
      color: white;
    }
  }

  .form-input-error {
    color: rgb(204, 136, 136);
    font-size: 11px;
  }
}

.solana-button {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 0;

  &:not(:has(.helio-background-modal)) div {
    text-align: center;
    max-width: 100% !important;
    width: 100% !important;
  }

  .helio-primary-button {
    background: $background-gradient !important;
    text-align: center;
    color: black !important;
    width: 100%;

    &:hover {
      background: $background-gradient-inverted !important;
      opacity: 0.9;
      color: black !important;
    }
  }
}

@import "./components/Passes/style";
@import "./components/RenewMemberships/style";

.memberships-tabs {
  display: flex;
  border: 1px solid $primary-blue;
  border-radius: 5px;

  .tab {
    padding: 5px 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;

    &.active {
      background: $background-gradient;
      background-blend-mode: overlay, normal;
      color: #18191c;
    }

    &:not(.active) {
      @include text-background-gradient;
    }
  }
}
