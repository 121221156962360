.genealogy-tree {
  display: flex;

  gap: 35px;
  margin-bottom: 35px;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.left-col {
      max-width: 320px;
    }
  }
}

.teams-accordion-container {
  margin-top: 32px
}

.tree-container,
.tree-scroll {
  &::-webkit-scrollbar-track {
    background: rgba($primary-blue, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-blue;
  }
}

@import "./components/UserModal/style";
@import "./components/TreeColorsKey/style";
