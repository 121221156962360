.banners-row {
  padding: 24px 0;
  border-bottom: 1px solid rgba(white, 0.1);

  @media screen and (max-width: 900px) {
    gap: 16px;
  }

  .title {
    font-size: 16px;
    margin-right: 12px;
    @include text-background-gradient;
  }

  .global-banner-container {
    margin: 0;
  }
}

.global-banner-container {
  background: $background-gradient;
  border-radius: 5px;
  margin: 24px 0 32px;
  padding: 8px;
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    background-color: var(--black);
    border-radius: 5px;
    display: block;
    height: calc(100% - 4px);
    left: 2px;
    position: absolute;
    top: 2px;
    width: calc(100% - 4px);
    z-index: -1;
  }
}