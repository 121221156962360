.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-between {
  align-items: space-between;
}

.items-end {
  align-items: flex-end;
}


.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-2 {
  gap: 0.75rem;
}

.gap-3 {
  gap: 0.25rem;
}

.gap-1 {
  gap: 1.625rem;
}

.w-full {
  width: 100%;
}

