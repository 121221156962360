.tag {
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);

  &.current {
    border-width: 3px;
  }

  &.white {
    color: gray;
    border-color: white;
  }

  &.gray {
    color: gray;
    border-color: gray;
  }

  &.green {
    color: white;
    border-color: #639157;
  }

  &.platinium {
    color: white;
    border-color: #284545;
  }

  &.gold {
    color: white;
    border-color: var(--primary-color);
  }

  &.silver {
    color: white;
    border-color: #515151;
  }

  &.brown {
    color: white;
    border-color: #453628;
  }

  &.red {
    color: white;
    border-color: #911e1e;
  }

  &.purple {
    color: white;
    border-color: #250730;
  }

  &.blue {
    color: white;
    border-color: #1d5075;
  }

  &.yellow {
    color: white;
    border-color: #dbd15c;
  }

  &.orange {
    color: white;
    border-color: #d66f20;
  }

  &.rose {
    color: white;
    border-color: #ea98c5;
  }

  &.sky {
    color: white;
    border-color: #2a94e0;
  }
}
