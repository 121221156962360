.sign-up-page {
  h1 {
    text-align: center;
    color: white;
  }

  h2 {
    text-align: center;
    color: white;
    font-size: 20px;
  }

  &.sign-up-success {
    p {
      color: white;
      text-align: center;
    }

    h2 {
      text-align: left;
    }

    .form-item {
      display: flex;
      flex-direction: column;
      span {
        color: white;
      }
    }
  }
}
