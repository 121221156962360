.money-input {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #121316;
  position: relative;

  &.disabled {
    opacity: 70%;
    cursor: not-allowed;
  }

  &.error {
    border-color: brown;
  }

  input {
    flex: 1;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #121316;
    font-weight: 500;
    color: var(--text-color);
    outline: none;
    transition: 0.4s background, 0.4s border-color;
    padding: 18px 29px;
    font-size: 16px;

    &.disabled {
      cursor: not-allowed;
    }

    &.sm {
      padding: 12px 25px;
    }

    &.focused {
      background-color: transparent;
      border: 1px solid var(--primary-color);
    }

    &.error {
      border-color: brown;
    }

    &.number {
      justify-content: center;
    }
  }

  .currency-symbol {
    padding-left: 12px;
    padding-right: 0;
    position: absolute;
    top: 11px;
    left: -2px;
    color: #949494;
  }

  .end-adornment {
    color: gray;
    font-weight: 600;
    font-size: 17px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.form-input-error {
  font-size: 11px;
  color: rgb(204, 136, 136);
  // margin-left: 20px;
}

.end-adornment {
  color: gray;
  font-weight: 600;
  font-size: 17px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

/* Hide the number input spin buttons */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the number input spin buttons (for Firefox) */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
