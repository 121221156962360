.meme-coin {
  background: linear-gradient(28deg, rgba(black, 0.3), rgba(white, 0.11));
  border-radius: 18px !important;
  box-shadow: -10px 3px 10px 3px #00000022;
  min-height: 390px;
  padding: 0 12px;
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    background-color: #27292b;
    background-image: url("../../../../../../public/images/match-card-bg-empty.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: calc(100% - 14px) calc(100% - 14px);
    border-radius: 18px;
    height: calc(100% - 4px);
    left: 2px;
    position: absolute;
    top: 2px;
    width: calc(100% - 4px);
    z-index: -1;
  }
  
  &.picked-card {
    background: $background-gradient-inverted;
  }
  
  &.winner-coin {
    background: $background-gradient-inverted;

    &::after {
      background-color: transparent;
      background-image: url("../../../../../../public/images/match-card-bg-empty.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: calc(100% - 14px) calc(100% - 14px);
    }
  }

  .picked-cont {
    position: absolute;
    padding: 3px 10px;
    background: $background-gradient;
    position: absolute;
    bottom: 4%;
    right: 8%;
    border-radius: 4px;

    span {
      font-size: 16px;
      color: #121214;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .card-head {
    margin-top: 2.5%;

    @media (max-width: 1650px) {
      margin-top: 3%;
    }

    @media (max-width: 1300px) {
      margin-top: 4%;
    }

    @media (max-width: 1200px) {
      margin-top: 3%;
    }

    h3 {
      margin-left: 50%;
      width: 50%;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .price-down {
    color: #e95757;
  }

  .price-up {
    color: #57e95d;
  }

  @media ($tablet-media) {
    min-height: unset;
  }

  .open-chart {
    position: absolute;
    bottom: 2.5%;
    left: 7%;
    display: flex;
    gap: 8px;
    cursor: pointer;

    span {
      font-size: 17px;
    }
  }

  .banner-cont {
    overflow: hidden;
    height: 150px;
    border-radius: 20px;
    margin: 0 10px;
    margin-bottom: 26px;
    position: relative;

    .banner {
      height: 150px;
      filter: blur(20px);
      border-radius: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .match-card-body {
    padding: 2% 4% 80px;
  }

  .head {
    overflow: hidden;
    margin-bottom: 13px;
    justify-content: flex-start;

    .head-gap {
      gap: 21px;
      flex-wrap: wrap;
    }

    img {
      width: 39px;
      height: 39px;
      border-radius: 100%;
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media ($mobile-media) {
    flex-direction: column;
  }

  &:not(.winner-coin) {
    .open-chart span {
      @include text-background-gradient;
    }
  }

  &.winner-coin {
    .card-head h3 {
      color: black;
    }

    .open-chart span {
      color: black;
    }
  }
}
