.webinar-leads-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 100%;
  position: relative;

  @media ($mobile-media) {
    padding-top: 2rem;
  }

  .mobile-phone {
    * {
      cursor: default;
    }

    .phone-input {
      background-color: transparent !important;
      
      .form-control {
        background-color: transparent !important;
        padding-left: 0;
      }
      
      .flag-dropdown {
        display: none;
      }
    }
  }
}
