@import "./UsageReport/style";
@import "./GameResultsReport/style";
@import "./WebinarLeadsReport/style";
@import "./components/ResultsBox/style";

.results-container {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;

  @media screen and (max-width: 1140px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 578px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
