.gamification-webinar {
  .btn {
    display: flex;
    padding: 13px 28px;

    &.default {
      background: $background-gradient;
      
      &:hover {
        background: linear-gradient(40deg, $primary-blue 40%, $primary-purple);
      }
    }

    &.bordered {
      color: $primary-blue;
      border-color: $primary-purple;
      @include text-background-gradient;
    }

    .icon-cont {
      height: unset;
      left: 0;
      margin-right: 19px;
      position: relative;
    }

    p {
      font-size: 16px;
      line-height: unset;
      text-transform: unset;
      font-weight: 600;
    }
  }

  .primary-text-gradient {
    @include text-background-gradient;
  }

  .nav-cont {
    display: flex;
    gap: 32px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    padding: 55px 74px;

    .img-cont {
      @media ($mobile-media) {
        display: flex;
        justify-content: center;
      }
    }

    .img-cont img {
      height: 47px;
      width: 150px;
    }

    @media ($mobile-media) {
      padding-left: 8%;
      padding-right: 8%;
      display: flex;
      flex-direction: column;

      .img-cont {
        margin-bottom: 10px;
      }
    }

    .button-cont {
      display: flex;
      gap: 17px;
      flex-wrap: wrap;
      justify-content: flex-end;

      @media ($mobile-media) {
        justify-content: center;
      }
    }
  }
}