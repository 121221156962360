.meme-games {
  .calendar-cont {
    position: relative;
  }

  .rbc-btn-group {
    &:last-of-type {
      display: none;
    }

    button {
      color: white;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
      }
    }
  }

  .rbc-toolbar-label {
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
  }

  .add-game-btn {
    position: absolute;
    right: 0;
  }

  .rbc-off-range-bg {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .rbc-day-bg + .rbc-today {
    background-color: transparent;
  }

  .rbc-event,
  .rbc-selected {
    background-color: rgba($primary-blue, 0.4);
  }
}

.meme-game-actions {
  .action {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}
