.dashboard {
  background: transparent;
  height: 100%;

  .MuiGrid-container {
    background: transparent;
  }

  .btn {
    display: flex;
    padding: 13px 28px;

    &.default {
      background: $background-gradient;
      
      &:hover {
        background: linear-gradient(40deg, $primary-blue 40%, $primary-purple);
      }
    }

    &.bordered {
      color: $primary-blue;
      border-color: $primary-purple;
      @include text-background-gradient;
    }

    .icon-cont {
      height: unset;
      left: 0;
      margin-right: 19px;
      position: relative;
    }

    p {
      font-size: 16px;
      line-height: unset;
      text-transform: unset;
      font-weight: 600;
    }
  }

  .nav-cont {
    display: flex;
    gap: 32px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    padding: 55px 74px;

    .img-cont {
      @media ($mobile-media) {
        display: flex;
        justify-content: center;
      }
    }

    .img-cont img {
      height: 47px;
      width: 150px;
    }

    @media ($mobile-media) {
      padding-left: 8%;
      padding-right: 8%;
      display: flex;
      flex-direction: column;

      .img-cont {
        margin-bottom: 10px;
      }
    }

    .button-cont {
      display: flex;
      gap: 17px;
      flex-wrap: wrap;
      justify-content: flex-end;

      @media ($mobile-media) {
        justify-content: center;
      }
    }
  }

  .top-section {
    display: flex;
    z-index: 2;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media ($tablet-media) {
      width: 100%;
      padding: 0 10%;
    }

    .text-1,
    .text-2 {
      color: rgba($color: white, $alpha: 0.7);
      display: flex;
      font-size: 18px;
      justify-content: center;
      line-height: 28px;
      max-width: 704px;
      text-align: center;
    }

    .text-1 {
      margin-bottom: 20px;
    }
  }

  .text-card {
    background-color: #212123;
    position: absolute;
    padding: 55px 75px;
    border-radius: 30px;
    max-width: 400px;
    box-shadow: 0px 0px 0px 0px #ffffff inset;

    img {
      left: unset !important;
      width: 27px !important;
      height: 27px !important;
      position: relative !important;
      margin: 0px !important;
      margin-right: 30px !important;
      max-width: unset !important;
      transform: unset !important;
      right: unset !important;
      padding-top: 2px;
    }

    &::before {
      content: "";
      border-radius: 30px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
      z-index: 0;
    }

    * {
      z-index: 1;
    }

    .flex {
      &.mb {
        margin-bottom: 30px;
      }
    }

    .flex.justify-center {
      justify-content: center;
    }

    .flex.align-center {
      align-items: center;
    }

    .info {
      font-size: 18px;
      font-weight: 500;
      color: white;
    }
  }

  .right-section {
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 80px 50px;

    .text-1 {
      color: white;
      font-size: 54px;
      font-weight: 600;
      line-height: 67px;
      margin-bottom: 30px;
    }

    .text-2 {
      font-weight: 400;
      color: #f4f4f4;
      font-size: 17px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }

  .container-1 {
    max-width: 1650px;
    margin: 0 auto;
    padding: 52px 74px 24px;

    @media ($tablet-media) {
      padding: 0 8%;
    }

    .text-container {
      .text-1 {
        font-size: 27.16px;
        max-width: 390px;
        letter-spacing: 0.31em;
        margin-bottom: 30px;
        @include text-background-gradient;

        @media ($mobile-media) {
          font-size: 20px;
        }
      }

      .text-2 {
        font-size: 85px;
        font-weight: 600;
        color: white;
        margin-bottom: 30px;
        overflow: visible;
        line-height: 85px;

        @media ($tablet-media) {
          font-size: 50px;
          line-height: 50px;
          width: 90%;
        }

        @media ($mobile-media) {
          font-size: 40px;
          line-height: 40px;
        }
      }

      .text-3 {
        font-size: 16px;
        color: rgb(179, 179, 179);
        margin-bottom: 30px;
        max-width: 680px;

        @media ($mobile-media) {
          max-width: unset;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }

  .container-2 {
    margin-bottom: 30px;
    margin-top: 50px;
    position: relative;

    @media ($mobile-media) {
      margin-top: 50px;
      position: relative;
    }

    img {
      position: absolute;
    }

    .bg-img {
      width: 40%;
      top: 20%;
      max-width: 550px;

      @media ($tablet-media) {
        width: 80%;
      }

      @media ($mobile-media) {
        width: 70%;
        max-width: 500px;
        top: 12%;
      }

      @media (max-width: 650px) {
        top: 16%;
      }

      @media (max-width: 550px) {
        top: 18%;
      }

      @media (max-width: 460px) {
        top: 20%;
      }

      @media (max-width: 430px) {
        top: 22%;
      }

      @media (max-width: 380px) {
        top: 24%;
      }
    }

    .left-section {
      max-width: 700px;
      z-index: 1;
      position: relative;

      img {
        width: 100%;
        margin-top: 100px;
        position: relative;
        margin-left: -35px;

        @media ($mobile-media) {
          margin-top: 80px;
          width: 100%;
        }
      }

      .text-card {
        position: absolute;
        z-index: 9999;
        position: relative;
        right: -130px;
        top: -185px;

        @media (max-width: 1270px) {
          right: -110px;
        }

        @media ($mobile-media) {
          position: relative;
          right: 0px;
          top: 0px;
          margin-top: -60px;
          width: calc(96vw - 45px - 45px);
          margin-left: 2vw;
          padding: 45px;
        }
      }
    }

    .right-section {

      @media ($tablet-media) {
        margin-bottom: 0px;
        padding-top: 50px;
      }

      @media ($mobile-media) {
        padding-bottom: 50px;
        padding-left: 8%;
        padding-right: 8%;
      }
    }

    ul {
      li {
        &::marker {
          color: $primary-blue;
          background: $background-gradient;
        }
      }
    }
  }

  .container-3 {
    margin-top: -130px;
    margin-bottom: 30px;
    position: relative;

    @media ($tablet-media) {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 50px;
    }

    @media ($mobile-media) {
      margin-top: 0px;
      position: relative;
    }

    img {
      position: absolute;
      right: 0;
    }

    .bg-img {
      max-height: 800px;
      max-width: 700px;
      object-fit: contain;
      object-position: right;
      top: -10%;
      width: 30%;
      
      @media (max-width: 1400px) {
        width: 50%;
      }
      
      @media ($tablet-media) {
        max-height: 700px;
        max-width: 550px;
        top: -8%;
        width: 70%;
      }

      @media ($mobile-media) {
        width: 90%;
      }

      @media (max-width: 500px) {
        width: 100%;
        top: -50px;
      }
    }

    .left-section {
      z-index: 1;
      position: relative;
      display: flex;

      @media ($tablet-media) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      @media ($mobile-media) {
        flex-direction: column;
      }

      img {
        margin-top: 80px;
        width: 100%;
        max-height: 530px;
        max-width: 622px;
        margin-right: -35px;

        @media ($tablet-media) {
          position: relative;
          margin-top: 0px;
        }

        @media ($mobile-media) {
          max-width: 500px;
          margin-left: auto;
          width: 100%;
        }
      }

      .text-card {
        position: absolute;
        z-index: 9999;
        right: 28%;
        width: 100%;
        bottom: -100px;

        @media (max-width: 1600px) {
          bottom: -3%;
        }

        @media (max-width: 1500px) {
          bottom: 0%;
        }

        @media (max-width: 1420px) {
          bottom: 5%;
        }

        @media (max-width: 1400px) {
          bottom: 5%;
        }

        @media (max-width: 1300px) {
          bottom: 10%;
        }

        @media ($tablet-media) {
          bottom: -30%;
        }

        @media ($mobile-media) {
          position: relative;
          right: 0;
          bottom: 0;
          margin-left: auto;
          margin-right: 2vw;
          margin-top: -100px;
          padding: 45px;
          width: calc(96vw - 45px - 45px);
        }
      }

      .shadow {
        width: 100px;
        height: 100px;
        right: 50%;
        bottom: -80px;
        z-index: 1000;
        position: absolute;
        background-color: transparent;
        transform: rotate(10.65deg);

        @media (max-width: 1600px) {
          bottom: 0%;
        }

        @media (max-width: 1500px) {
          bottom: 2%;
        }

        @media (max-width: 1420px) {
          bottom: 7%;
        }

        @media (max-width: 1300px) {
          bottom: 12%;
        }

        @media ($tablet-media) {
          bottom: -28%;
        }

        @media ($mobile-media) {
          width: 20px;
          height: 20px;
          z-index: -1;
          bottom: 20%;
        }

        gap: 0px;
        box-shadow: rgba($color: white, $alpha: 0.1) 0px 22px 700px 200px;
        opacity: 0.8;
      }
    }

    .right-section {
      display: flex;
      z-index: 2;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 100px;
      padding-right: 100px;

      @media ($tablet-media) {
        padding: 200px 80px 20px;
      }

      @media ($mobile-media) {
        padding-top: 80px;
        padding-left: 8%;
        padding-right: 8%;
      }
    }
  }

  &.loading {
    filter: blur(2px);
  }

  .container-4 {
    margin-bottom: 30px;
    margin-top: 5%;
    position: relative;

    @media ($mobile-media) {
    margin-bottom: 0;
    }

    img {
      position: absolute;
    }

    .bg-img {
      width: 50%;
      top: 0;
      z-index: 1;
      max-width: 700px;

      @media ($tablet-media) {
        width: 80%;
        max-width: 700px;
        top: -15%;
      }

      @media ($mobile-media) {
        top: -5%;
        width: 100%;
      }
    }

    .left-section {
      position: relative;
      z-index: 1;

      @media ($mobile-media) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
      }

      img {
        margin-top: 100px;
        max-width: 350px;
        width: 100%;
        left: 25%;
        position: relative;

        @media ($tablet-media) {
          margin-top: 0px;
          left: 60%;
        }

        @media ($mobile-media) {
          width: 100%;
          max-width: 350px;
          left: 50%;
          right: 50%;
          transform: translate(-50%, 0);
        }
      }

      .text-card {
        position: absolute;
        z-index: 9999;
        left: 43%;
        bottom: -150px;

        .info {
          width: 248px;
        }

        @media ($tablet-media) {
          left: 100%;
          bottom: -100px;
        }

        @media ($mobile-media) {
          position: relative;
          left: 0;
          bottom: 0;
          margin-right: auto;
          margin-left: 2vw;
          margin-top: -100px;
          padding: 45px;
          width: calc(96vw - 45px - 45px);
        }
      }
    }

    .right-section {
      display: flex;
      z-index: 2;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 150px;
      padding-right: 200px;
      padding-left: 50px;

      @media ($tablet-media) {
        padding: 150px 80px;
      }

      @media ($mobile-media) {
        margin-top: 0px;
        padding: 50px 8% 100px;
      }
    }
  }

  .container-5 {
    margin-top: 150px;
    padding: 300px 74px 230px 74px;
    background-color: black;
    position: relative;
    // background: linear-gradient(45deg, rgba(0, 0, 0, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%);
      transform: rotate(-180deg);
      z-index: 0;
    }

    * {
      z-index: 1;
    }

    @media ($tablet-media) {
      margin-top: 0px;
    }

    .cont-head,
    .cont-body {
      max-width: 1031px;
      margin: 0 auto;

      @media ($mobile-media) {
        flex-direction: column;
      }

      &.justify-center {
        justify-content: center;
      }
    }

    .cont-head {
      margin-bottom: 76px;
      gap: 70px;

      @media ($mobile-media) {
        margin-bottom: 50px;
        gap: 40px;
      }

      h2 {
        color: white;
        font-size: 56px !important;
        line-height: 67px;
        font-weight: 600;
        margin-bottom: 30px;

        @media ($mobile-media) {
          font-size: 45px !important;
          line-height: 60px;
        }
      }

      p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        color: #f4f4f4;
      }
    }

    .cont-body {
      display: flex;
      justify-content: space-between;

      .mult-cont {
        max-width: 270px;


        img {
          width: 37px;
          height: 37px;
          margin-bottom: 16px;
          margin-top: 5px;
          margin-right: 10px;
        }

        h3 {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
          margin-bottom: 15px;
          color: white;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          color: white;
        }
      }
    }

    &.ambassadors {
      margin-top: 0px;
      padding: 200px 80px 120px;

      @media ($mobile-media) {
        padding: 120px 8%;
      }

      .cont-head {
        margin-bottom: 69px;

        h2 {
          margin-bottom: 0px;
        }
      }

      .cont-body {
        display: grid;
        gap: 100px;
        grid-template-columns: 1fr 1fr 1fr;

        @media ($tablet-media) {
          grid-template-columns: 1fr 1fr;
        }

        @media ($mobile-media) {
          grid-template-columns: 1fr;
          gap: 50px;
        }

        .mult-cont {
          max-width: unset;

          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            @include text-background-gradient;
          }

          p {
            font-size: 16px;
            line-height: 27px;
            font-weight: 500;
          }
        }
      }
    }

    @media ($mobile-media) {
      padding: 150px 8%;
    }
  }

  .container-6 {
    padding: 220px 74px 330px 74px;
    position: relative;
    gap: 128px;
    display: flex;

    &::before {
      filter: brightness(30%);
      background-position: 50% 30%;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("../../../public/images/bull-bg.png");
      z-index: 0;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      left: 0;

      @media ($mobile-media) {
        background-position: center;
        background-size: cover;
      }
    }

    .cont-body {
      z-index: 100;
      position: relative;
      max-width: 1031px;
      margin: 0 auto;

      @media ($mobile-media) {
        flex-direction: column;
      }

      display: flex;
      justify-content: space-between;
      gap: 40px;

      .left,
      .right {
        max-width: 425px;

        @media ($mobile-media) {
          max-width: unset;
        }
      }

      .right {
        gap: 40px;
        justify-content: space-between;
        padding-bottom: 40px;

        .right-cont {
          display: flex;

          .border {
            height: 100%;
            width: 5px;
            min-width: 5px;
            background-color: var(--primary-color);
            margin-right: 40px;
            border-radius: 5px;
            position: relative;

            @media ($mobile-media) {
              margin-right: 20px;
            }

            &::after {
              border-radius: 5px;
              content: "";
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              background: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
            }
          }
        }

        @media ($mobile-media) {
          padding-bottom: 0px;
        }
      }

      h2,
      p,
      h4 {
        color: white;
      }

      h2 {
        font-size: 56px !important;
        font-weight: 600;
        line-height: 67px;
        margin-bottom: 30px;

        @media ($mobile-media) {
          font-size: 45px !important;
          line-height: 60px;
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      h4 {
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;

        @media ($mobile-media) {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }

    @media ($mobile-media) {
      padding: 100px 8%;
    }
  }

  .container-7 {
    padding: 190px 74px 200px 74px;
    position: relative;

    &::before {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../public/images/ps-bg.png");
      z-index: 0;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      left: 0;
    }

    .cont-head {
      z-index: 100;
      position: relative;
      max-width: 1031px;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 50px;

      @media ($mobile-media) {
        margin-bottom: 40px;
      }

      h1 {
        font-size: 56px;
        font-weight: 600;
        line-height: 67px;
        color: white;

        @media ($mobile-media) {
          font-size: 45px;
          line-height: 60px;
        }
      }
    }

    .cont-body {
      z-index: 100;
      position: relative;
      max-width: 1031px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      gap: 128px;

      @media ($mobile-media) {
        flex-direction: column;
      }

      display: flex;
      justify-content: space-between;
      gap: 40px;

      .left,
      .right {
        max-width: 425px;

        @media ($mobile-media) {
          max-width: unset;
        }
      }

      h2,
      p {
        color: white;
      }

      h2 {
        font-size: 36px;
        font-weight: 600;
        line-height: 43px;
        margin-bottom: 15px;
        @include text-background-gradient;
      }

      p {
        font-weight: 400;
        font-size: 21px;
        line-height: 35px;
      }
    }

    @media ($mobile-media) {
      padding: 100px 8%;
    }
  }

  .container-8 {
    padding: 0px 74px 142px 74px;
    position: relative;
    text-align: center;

    .card {
      position: relative;
      margin-bottom: 0px;
      width: calc(100% - 74px - 74px);
      background-color: var(--primary-color);
      max-width: calc(1031px - 74px - 74px);
      padding: 107px 74px 126px 74px;
      border-radius: 16px;
      margin-left: auto;
      margin-right: auto;
      transform: translate(0%, -40%);

      @media ($mobile-media) {
        transform: translate(0%, -50px);
        margin-bottom: 50px;
        padding: 74px 8%;
        width: 84%;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
        z-index: 0;
        border-radius: 16px;
      }

      .btn {
        border-color: black;
        color: black;
      }

      h5 {
        z-index: 1;
        font-size: 20px;
        color: black;
        font-weight: 500;
        line-height: 33px;
      }

      h1 {
        z-index: 1;
        font-size: 67px;
        font-weight: 600;
        line-height: 81px;
        color: black;
        margin-bottom: 42px;
      }
    }

    img {
      width: 98px;
      margin-bottom: 27px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      color: rgba($color: white, $alpha: 0.4);
    }

    @media ($mobile-media) {
      padding: 100px 8%;
      padding-top: 0px;
    }

    .big-button-container {
      align-items: center;
      justify-content: center;
      min-height: 120px;
      position: relative;
      top: -60px;

      .big-button {
        padding: 22px 150px;
        font-size: 22px;

        @media screen and (max-width: 900px) {
          font-size: 18px;
          padding: 18px 120px;
        }

        @media screen and (max-width: 578px) {
          font-size: 16px;
          padding: 18px 24px;
          max-height: none;
        }
      }
    }
  }
}

.app-loader {
  background: black;
  z-index: 9999999;
  font-weight: 600;
}
