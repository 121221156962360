#container-tree {
  @media screen and (max-width: 768px) {
    height: calc(100% - 60px);
  }
}

.tree-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 280px);
  max-height: 100%;
  overflow: auto;
  
  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
    height: calc(100vh - 200px);
  }

  .layout-card {
    border-radius: 8px;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%),
      #141518;
    box-shadow: 0px 60px 120px 0px rgba(0, 0, 0, 0.15);
  
    @media screen and (max-width: 768px) {
      margin-top: 24px !important;
    }
  }

  .card-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: calc(100vw - 400px);
    padding-top: 32px;
  
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }

  &.overflow-auto {
    overflow: auto;
  }
}
