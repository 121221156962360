.game-header {
  margin-top: 38px;

  .tabs {
    display: flex;

    .tab {
      padding: 13px 26px;
      cursor: pointer;
      background-color: black;
      border-radius: 20px 20px 0px 0px;
      transition: 0.4s background-color;

      span {
        color: white;
        opacity: 0.5;
        font-size: 18px;
        font-weight: 500;
        transition:
          0.4s opacity,
          0.4s color;
      }

      &.active {
        background-color: #1c1c20;

        span {
          opacity: 1;
          @include text-background-gradient;
        }
      }
    }
  }

  @media ($mobile-media) {
    flex-direction: column;
    text-align: center;
  }
}
