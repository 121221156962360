.nav-container {
  display: flex;
  gap: 32px;
  padding: 55px 74px;
  margin-bottom: 10px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;

  .img-container {
    @media ($mobile-media) {
      display: flex;
      justify-content: center;
    }
  }

  .img-container img {
    width: 150px;
    height: 47px;
  }

  @media ($mobile-media) {
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
    flex-direction: column;

    .img-container {
      margin-bottom: 10px;
    }
  }

  .button-container {
    display: flex;
    gap: 17px;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media ($mobile-media) {
      justify-content: center;
    }

    .btn {
      padding: 13px 28px;
      display: flex;

      .icon-cont {
        left: 0;
        margin-right: 19px;
        position: relative;
        height: unset;
      }

      p {
        font-size: 16px;
        line-height: unset;
        text-transform: unset;
        font-weight: 600;
      }
    }
  }
}

.section-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 74px;
  max-width: 1100px;
  justify-content: flex-end;
  position: relative;

  @media ($tablet-media) {
    padding: 0px 74px;
  }

  @media ($mobile-media) {
    flex-direction: column;
    padding-left: 8%;
    padding-right: 8%;
  }

  .text-container {
    z-index: 2;
    position: absolute;
    left: 74px;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ($mobile-media) {
      position: relative;
      width: 100%;
      left: 0px;
      margin-bottom: 30px;
    }

    .subtitle {
      font-size: 24px;
      color: var(--primary-color);
      max-width: 390px;
      letter-spacing: 0.31em;
      margin-bottom: 30px;

      @media ($mobile-media) {
        font-size: 20px;
      }
    }

    .title {
      font-size: 50px;
      font-weight: 600;
      color: white;
      margin-bottom: 30px;
      overflow: visible;
      line-height: 110%;

      @media ($tablet-media) {
        width: 90%;
        font-size: 40px;
      }

      @media ($mobile-media) {
        font-size: 30px;
      }
    }

    .content {
      font-size: 16px;
      color: rgb(179, 179, 179);
      max-width: 390px;
      margin-bottom: 30px;

      @media ($mobile-media) {
        width: 90%;
        max-width: unset;
        font-size: 16px;
      }
    }
  }

  .image-container {
    position: relative;
    z-index: 1;
    width: 600px;
    height: 740px;

    @media ($mobile-media) {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
    }

    img {
      width: 100%;
      height: 100%;
      z-index: 99999;
      position: relative;

      @media ($tablet-media) {
        width: 100%;
      }
    }

    .img-shadow {
      width: 100px;
      height: 100px;
      top: 75%;
      left: 50%;
      z-index: 10000;
      position: absolute;
      background-color: transparent;
      transform: rotate(10.65deg);

      @media ($mobile-media) {
        width: 20px;
        height: 20px;
      }

      gap: 0px;
      box-shadow: rgba($color: white, $alpha: 0.1) 0px 22px 700px 200px;
    }
  }
}

.footer-container {
  padding: 100px 32px;
  position: relative;
  text-align: center;

  img {
    width: 98px;
    margin-bottom: 27px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: rgba($color: white, $alpha: 0.4);
  }

  @media ($mobile-media) {
    padding: 100px 8%;
    padding-top: 0px;
  }
}
