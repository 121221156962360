.admin-booster-lead {
}

.admin-ambassadors-container {
  position: relative;
  width: 100%;

  .options {
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    
    button {
      background: #28282b;
      border: none;
      color: white;
      cursor: pointer;
      padding: 6px 0;
      display: flex;
      font-size: 16px;
      justify-content: center;
      width: 100%;
      
      &:hover {
        background: rgba(#28282b, 0.98);
      }
    }
  }
}
