.text-input {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #121316;
  width: 100%;

  &.centered-input {
    input {
      text-align: center;
    }
  }

  &.disabled {
    input {
      opacity: 70%;
      cursor: not-allowed;
    }
  }

  &.error {
    border-color: brown;
  }

  input {
    flex: 1;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #121316;
    font-weight: 500;
    color: var(--text-color);
    outline: none;
    transition:
      0.4s background,
      0.4s border-color;
    padding: 18px 29px;
    font-size: 16px;
    width: 100%;

    &.disabled {
      cursor: not-allowed;
    }

    &.sm {
      padding: 12px 25px;
    }

    &.focused {
      background-color: transparent;
      border: 1px solid var(--primary-color);
    }

    &.error {
      border-color: brown;
    }

    &.number {
      justify-content: center;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: var(--text-color);
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  textarea {
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #121316;
    width: 100%;

    flex: 1;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #121316;
    font-weight: 500;
    color: var(--text-color);
    outline: none;
    transition:
      0.4s background,
      0.4s border-color;
    padding: 18px 29px;
    font-size: 16px;

    &.sm {
      padding: 12px 25px;
    }

    &.focused {
      background-color: transparent;
      border: 1px solid var(--primary-color);
    }

    &.error {
      border-color: brown;
    }
  }

  &.absolute-adornment {
    position: relative;

    input {
      padding-left: 50px;
      padding-right: 50px;
    }

    .adornment {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      left: 11px;

      &.right-adornment {
        right: 11px;
        left: unset;
      }
    }
  }
}

.form-input-error {
  font-size: 11px;
  color: rgb(204, 136, 136);
  // margin-left: 20px;
}

/* Hide the number input spin buttons */
input[type="text"]::-webkit-inner-spin-button,
input[type="text"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the number input spin buttons (for Firefox) */
input[type="text"],
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
