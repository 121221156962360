.richContent{
  h1, h2, h3, h4, h5, h6 {
    margin: 1em 0;
    font-weight: bold;
  }

  h1 {
    font-size: 1.75em;
    em{font-size: 1.75em;}
  }

  h2 {
    font-size: 1.5em;
    em{font-size: 1.5em;}
  }

  h3 {
    font-size: 1.25em;
    em{font-size: 1.25em;}
  }

  h4 {
    font-size: 1em;
    em{font-size: 1em;}
  }

  h5 {
    font-size: 0.875em;
    em{font-size: 0.875em;}
  }

  h6 {
    font-size: 0.75em;
    em{font-size: 0.75em;}
  }

  p {
    line-height: 1.6;
    margin: 1em 0;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ul{
    list-style-type: disc;
  }
  ol{
    list-style-type: decimal;
  }
  ul, ol {
    margin: 1em 0;
    padding-left: 2em;

    li {
      margin: 0.5em 0;
    }
  }

  blockquote {
    font-style: italic;
    color: #999;
    border-left: 4px solid #ccc;
    margin: 1em 0;
    padding-left: 1em;
  }

  img,iframe,video {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em 0;
    border-radius: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;

    th, td {
      border: 1px solid #ddd;
      padding: 0.5em;
    }

    th {
      background-color: #f5f5f5;
    }
  }
}
