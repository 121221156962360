.admin-video-page {
  .video-button {
    margin-top: 0 !important;
    padding: 12px 20px !important;
    
    @media screen and (max-width: 578px) {
      justify-content: center;
      padding: 12px !important;
      width: 100% !important;

      .icon-cont {
        margin-right: 8px;
      }
    }
  }
}
