.lookup-history-card {
  border-radius: 18px;
  box-shadow: 0 0 18px 12px rgba(0, 0, 0, 0.04);
  padding: 48px;
  
  @media screen and (max-width: 578px) {
    border-radius: 16px;
    padding: 24px 16px;
  }
}
