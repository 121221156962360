.single_table{
  width: 100%;
  border: 1px solid #e5e5e5ac;
  border-radius: 6px;
  td,th{
    padding: 5px 8px;
    text-align: left;
    border-bottom: 1px solid #e5e5e5ac;
    font-size: 14px;
  }
  th{
    font-weight: 700;
  }
  tr:last-child{
    td,th{
      border-bottom: 0px;
      
    }
  }
  &.borderless{
    border: 0px;
    th,td{
      border: 0px;
    }
  }
}
