.notifications-page {

  .notifications-table {
    .read-notification-button {
      color: white;
      cursor: pointer;
      background: none;
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 16px;
      height: 100%;
      text-align: left;
      width: 100%;

      &:hover {
        color: var(--primary-color);
      }

      &.unread {
        color: var(--primary-color);
        
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}