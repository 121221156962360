.preview-profile-pic {
  width: 200px;
  max-height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 200px;
  }
}
