.home {
  .table {
    .first-column {
      @include text-background-gradient;
    }
  }

  a {
    @include text-background-gradient;
  }
}

a {
  color: var(--primary-color);
}

.chatgpt-aq {
  .conversation {
    min-height: 300px;
    max-height: 450px;
    overflow: auto;

    .question-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      margin-bottom: 10px;

      .user-icon {
        background-color: gray;
        border-radius: 36px;
        font-size: 37px;
        padding: 7px;
      }

      .message {
        display: flex;
        flex-direction: column;

        .time {
          color: gray;
          font-size: 12px;
          display: flex;
          justify-content: flex-end;
        }

        .question {
          background-color: var(--secondary-color);
          border-radius: 10px 0 10px 10px;
          padding: 15px 10px;
        }
      }
    }

    .answer-container {
      display: flex;
      gap: 4px;
      margin-bottom: 15px;

      img {
        height: 40px;
        display: flex;
        align-items: center;
      }

      .message {
        display: flex;
        flex-direction: column;

        .answer {
          background: #2b2d31;
          border-radius: 0 10px 10px 10px;
          padding: 15px 10px;
          margin-right: 40px;
          overflow-y: auto;
          width: fit-content;
        }

        .time {
          color: gray;
          font-size: 12px;
        }
      }
    }
  }
}

.meme-coins-page {
  @import "./components/memeGame/OthersCont/style";

  .locked-price-cont {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    @include text-background-gradient;
    font-size: 22px;
    font-weight: bold;

    @media ($mobile-media) {
      font-size: 18px;
    }
  }

  .countdown {
    display: flex;
    align-items: center;

    .primary-color,
    .primary-text-gradient {
      text-align: right;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .gap-2 {
    gap: 30px;
  }

  .coins-chart-legend {
    display: flex;
    gap: 6px;
    justify-content: space-between;

    span {
      margin-top: -20px;
      font-size: 12px;
    }
  }

  &.meme-coins-page {
    @media ($mobile-media) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .meme-coins {
    min-height: 323px;
    position: relative;

    .price-badge {
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.34) 0%, rgba(255, 255, 255, 0.34) 100%);
      padding: 4px 20px;
      border-radius: 8px;

      span {
        font-weight: 500;
        font-size: 20px;
        color: white;

        &.primary {
          @include text-background-gradient;
        }
      }
    }

    .vs-image {
      width: 133px;
      height: 133px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      position: absolute;
    }

    .today-extra {
      width: 500px;

      .potential-points {
        display: flex;
        border-radius: 8px;

        .left,
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 15px 28px;

          span {
            font-weight: 500;
            font-size: 16px;
          }
        }

        .right span {
          color: var(--black);
          font-size: 32px;
        }

        .left {
          flex: 1;
          border-radius: 6px;
          background-color: #28292b;
          position: relative;
          z-index: 1;
        }

        .right {
          position: relative;
          z-index: 0;
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: calc(100% + 15px);
            background: $background-gradient-inverted;
            border-radius: 8px;

            @media screen and (max-width: 1200px) {
              height: calc(100% + 15px);
              top: unset;
              bottom: 0;
              width: 100%;
            }
          }
        }

        &.hidden {
          display: none;
        }
      }
    }

    .multipliers-cont {
      &.meme-coin-data {
        @media ($mobile-media) {
          flex-direction: column;
          gap: 0px;
        }
      }

      .layout-card {
        background: linear-gradient(0deg, #141518, #141518);
        padding-top: 28px;
        padding-bottom: 28px;

        .head {
          margin-bottom: 23px;
          padding-bottom: 23px;

          h4 {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0px;
            text-transform: uppercase;
          }

          border-bottom: 1px solid rgba($color: white, $alpha: 0.1);
        }
      }

      .won-card {
        .layout-card {
          background: $background-gradient;
          padding-left: 10px;
          padding-right: 10px;

          .head {
            margin-left: 14px;
            border-bottom: 0;
            margin-bottom: 0px;
            margin-top: 0px;
            margin-right: 14px;

            h4 {
              color: #121316;
            }
          }

          .c-body {
            padding: 17px 14px;
            background-color: var(--primary-color);
            border-radius: 8px;
            box-shadow:
              0px 3px 6px 0px var(--primary-color) inset,
              0px -1px 0px 0px var(--primary-color),
              0px 1px 0px 0px #ffffffa6,
              0px -2px 2px 0px #ffffff40;
            position: relative;

            .multiplier-cont {
              h2 {
                color: #793300;
                box-shadow: 0px 0px 3px 0px #ffffffa6 0px 1px 1px 0px #000000 0px 1px 0px 0px #ffffff40 inset;
              }

              h5 {
                color: black;
              }
            }

            &::after {
              border-radius: 8px;
              content: "";
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              background: linear-gradient(45deg, rgba(255, 255, 255, 0.22) 0%, rgba(0, 0, 0, 0.22) 86.24%);
            }
          }
        }
      }
    }

    @media ($tablet-media) {
      flex-direction: column;
    }

    .layout-card {
      background-color: #0a0b0e;
      position: relative;
      border-radius: 8px;
      box-shadow: 0px 60px 120px 0px #00000026;

      * {
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: (45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%);
        z-index: 1;
      }
    }

    .matchup-card {
      position: relative;

      .meme-coins {
        background-color: #1c1c20;
        padding: 100px 25px 40px;
        border-radius: 20px;
        justify-content: flex-start;
      }

      .meme-coin {
        border-radius: 8px;
      }
    }

    @import "./components/memeGame/VoteCoin/style";
  }

  @import "./components/memeGame/Header/style";
}

.meme-coins-chart-cont {
  .coin-img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .x-scale {
    gap: 10px;
    justify-content: space-between;
    display: flex;
    margin-top: -20px;

    span {
      color: white;
      user-select: none;
      font-size: 11px;

      @media (max-width: 540px) {

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
          display: none;
        }
      }

      @media (max-width: 360px) {

        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
}

.meme-game-multipliers-cont {
  display: flex;
  gap: 8px;

  &.bordered {
    border-radius: 10px;
    border: 1px solid rgba($color: white, $alpha: 0.1);
    padding: 12px;
  }

  .multiplier-cont {
    h2 {
      font-size: 24px;
      font-weight: 500;
      color: var(--primary-color);
    }

    h5 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

@mixin data-cont {
  .data-cont {
    display: flex;
    gap: 0px 8px;

    span {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 21px;

      &.label {
        opacity: 0.7;
        font-size: 400;
      }

      &.value {
        font-weight: 600;

        .dollar {
          font-size: 10px;
        }
      }
    }
  }
}

@import "./components/memeGame/VoteModal/style";

.meme-coin-data {
  @include data-cont;

  .card-chip {
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 3px 6px;
    border-radius: 99px;

    &.with-bg {
      background: #b2fe664d;
      border: 0px;

      &.not-growing {
        background: #fe71664d;
      }
    }

    &.with-border {
      border: 1.5px solid #b2fe664d;

      &.not-growing {
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    span {
      font-size: 12px;
      font-weight: 500;
    }

    .chip-bubble {
      width: 11px;
      height: 11px;
      background: #deff5c;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      &.not-growing-bubble {
        background-color: #ff665c;
      }

      svg {
        z-index: 2;
      }

      &::before {
        border-radius: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
        z-index: 1;
      }
    }
  }
}

@import "./components/memeGame/VoteModal/style";

@import "./components/memeGame/MultiplierModal/style";
@import "./mobile";

.modal-data {
  .grid-cont {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media ($mobile-media) {
      grid-template-columns: 1fr;
    }

    @include data-cont;
  }

  .ai-data {
    margin-top: 16px;

    p {
      font-size: 16px;
    }

    .categories {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-top: 4px;

      .category {
        padding: 0px 8px;
        background-color: rgba($primary-blue, 0.3);
        border-radius: 8px;

        span {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}

.matchup {
  &-container {
    display: flex;
    flex-direction: column;
    position: relative;

    &:first-of-type {
      .matchup-card {
        .meme-coins {
          border-radius: 0 20px 20px 20px;
          
          @media screen and (max-width: 434px) {
            border-radius: 0 0 20px 20px;
          }
        }
      }
    }
  }

  &-header {
    display: flex;
    width: calc(100% - 500px);
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 30px 22px 18px;
    position: absolute;
    align-items: center;
    z-index: 3;

    &::after {
      content: '';
      height: 1px;
      display: block;
      left: 26px;
      background-color: rgba(white, 0.1);
      width: calc(100% - 48px);
      position: absolute;
      bottom: 0;

      @media screen and (max-width: 850px) {
        left: 15px;
        width: calc(100% - 30px);
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    @media screen and (max-width: 850px) {
      padding: 24px 15px 22px;
    }

    @media screen and (max-width: 578px) {
      flex-direction: column;
      gap: 10px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .time {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .controls {
      display: flex;
      align-items: center;
      gap: 12px;
      .btn {
        display: flex;
        padding: 4px 6px;

        &:disabled {
          opacity: 50%;
        }
      }
    }
  }

  &-footer {
    bottom: 5%;
    display: flex;
    gap: 16px;
    justify-content: center;
    position: absolute;
    width: calc(100% - 500px);
    z-index: 2;
    
    @media screen and (max-width: 1260px) {
      bottom: 25px;
    }
    
    @media screen and (max-width: 1200px) {
      display: none;
    }
    
    .btn {
      padding: 0;
      box-sizing: border-box;
      height: 10px;
      width: 10px;

      &.default {
        &:hover {
          background: $background-gradient-inverted;
        }
      }

      &.bordered {
        &:hover {
          background-color: rgba($primary-blue, 0.75);
        }
      }
    }
  }
}

.pick-button {
  &.disabled {
    background: rgba(grey, 0.5);
    
    &:hover {
      background: rgba(grey, 0.5);
    }
  }
}
