.view-table {
  .view-table-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: solid rgb(255, 255, 255, 0.15) 1px;
    padding-bottom: 10px;
    margin-bottom: 25px;

    .view-table-name {
      font-size: 16px;
      font-weight: 500;
    }

    .view-table-value {
      font-size: 18px;
      font-weight: 700;
      color: var(--primary-color);
    }
  }

  .view-table-body {
    .view-table-name {
      font-size: 14px;
      font-weight: 400;
    }

    .view-table-value {
      font-size: 16px;
      font-weight: 500;
      color: var(--primary-color);
    }
  }
}
