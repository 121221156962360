.meme-coins-page {
  margin-left: -15px;
  margin-right: -15px;

  .meme-coins {
    .vote-coin-container {
      row-gap: 100px;

      .pick-button-container {
        .pick-button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .meme-coin .head {
      overflow: visible;

      .head-gap {
        flex-direction: row;
        margin-top: 1px;

        img {
          visibility: visible;
          display: block;
        }
      }
    }

    .match-card-body {
      .coin-name-xs {
        display: none;
      }
    }

    .today-extra {
      .potential-points-mobile {
        visibility: hidden;
        display: none;
      }

      // .data .row-item {
      //   display: flex;
      //   justify-content: space-between;
      //   gap: 8px;
      //   padding: 5px 0;
      //   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      // }

      .coins-chart {
        flex-basis: 100%;
      }
    }
  }

  .price-badge {
    width: fit-content;
    visibility: visible;
    display: block;
  }

  .price-badge-mobile {
    visibility: hidden;
    display: none;
  }

  @media ($desktop-media) {
    .meme-coins {
      flex-basis: 60%;

      .matchup-card {
        flex-direction: row;
      }
    }
  }

  @media ($tablet-media) {
    .meme-coins {
      .matchup-card {
        flex-direction: column;
      }

      .vote-coin-container {
        row-gap: 100px;
      }

      .vs-image {
        top: 52%;
      }
    }

    .game-header {
      flex-direction: column-reverse;
    }

    .meme-coins {
      .today-extra {
        width: unset;

        .potential-points {
          visibility: visible;
          display: block;
          flex-direction: row;

          .right {
            padding: 5px 28px;
          }
        }

        .potential-points-mobile {
          visibility: hidden;
          display: none;
        }

        .coins-chart {
          flex-basis: 70%;
        }
      }
    }
  }

  @media ($mobile-media) {
    .meme-coins {
      .game-container {
        padding: 100px 15px 15px !important;
      }

      .meme-coin {
        padding: 2% 5% 10% 5%;
      }

      .matchup-card {
        .match-card-body .head .head-gap {
          gap: 5px;

          .coin-name-xs {
            display: none;
          }

          img {
            height: 25px;
            width: 25px;
          }

          h4 {
            font-size: 13px;
          }

          .card-chip {
            padding: 1px 2px;

            span {
              font-size: 10px;
            }
          }
        }

        .data-cont {
          flex-direction: column;
        }
      }

      .game-container-box {
        background-repeat: no-repeat;
        background: linear-gradient(0deg, #202123, #202123),
          linear-gradient(48.18deg, rgba(0, 0, 0, 0.04) -5.56%, rgba(255, 255, 255, 0.04) 100%);
        border: 1px solid #2e2e2e;
        box-shadow: -30px 18px 44px 0px #00000059;
        border-radius: 10px;
      }

      .vote-coin-container {
        row-gap: 100px;

        .meme-coin {
          background: none;
          box-shadow: none;
          &::after {
            background-image: none !important;
            background: none !important;
            height: 100%;
            width: 100%;
          }
        }

        .pick-button {
          padding: 15px 10px;
          margin-top: 10px;

          .pick-winner-button-icon {
            height: 15px;
            width: 15px;
            display: none;
          }
        }

        .game-coin-0 {
          border-right: 1px solid rgba(133, 133, 133, 0.199);
          padding: 10px;
        }

        .game-coin-1 {
          border-left: 1px solid rgba(128, 126, 126, 0.11);
          padding: 10px;
        }

        .picked {
          border: 1px solid var(--primary-color) !important;
          border-radius: 10px;
          padding-bottom: 40px !important;

          .open-chart {
            bottom: -16px;
            left: 0;
          }

          .picked-cont {
            bottom: -20.5%;
            left: 0;
          }

          .picked-cont-today {
            bottom: -55px;
            left: calc(50% - 36px);
          }

          .redeem-container-button {
            position: relative;
            display: flex;
            width: 100%;
            z-index: 5 !important;
            bottom: -27px;
            justify-content: center;
            margin-top: 15px;
            left: 40px;
          }
        }
      }

      .vote-coin-header {
        flex-direction: row;

        .countdown {
          .text {
            display: none;
          }
        }
      }

      .vs-image {
        top: calc(11% + 80px);
        width: 90px;
        height: 90px;
      }

      .today-extra {
        min-width: 200px;
        padding: 5px;
        margin-top: 20px;

        .left {
          span {
            font-size: 15px !important;
          }
        }

        .right {
          span {
            font-size: 15px !important;
          }
        }
      }

      .meme-coin .head {
        overflow: visible;
        margin-top: 6%;

        .head-gap {
          flex-direction: row;
          margin-top: -20px;

          img {
            visibility: hidden;
            display: none;
          }

          .price-container {
            display: flex;
            gap: 5px;
          }
        }
      }

      .layout-card .bordered-cont h3 {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .meme-coin .open-chart span {
        font-size: 17px;
        text-wrap: nowrap;
      }

      .meme-coin .open-chart {
        bottom: 0;
        left: 0;
        justify-content: center;
        width: 100%;
      }

      .meme-coin .picked-cont {
        right: 0;
        left: 13%;
        width: fit-content;

        span {
          font-size: 13px;
          text-wrap: nowrap;
        }
      }

      .meme-coin .card-head h3 {
        white-space: wrap;
        max-width: 150px;
        width: 150px;
      }

      .meme-coin .card-head {
        overflow: visible;
      }
    }

    .game-header {
      flex-direction: column-reverse;

      .tabs .tab {
        padding: 10px 15px;
      }
    }

    .meme-coins {
      .today-extra {
        .potential-points {
          visibility: hidden;
          display: none;
        }

        .potential-points-mobile {
          visibility: visible;
          display: block;
          flex-basis: 30%;
          justify-content: start;
          align-items: center;
          align-content: center;
        }

        .coins-chart {
          flex-basis: 70%;
        }
      }
    }
  }

  @media (max-width: 599px) {
    .meme-coins {
      .game-container {
        padding: 100px 15px 15px !important;
      }

      .meme-coin {
        padding: 2% 5% 10% 5%;
      }

      .meme-coin .match-card-body {
        padding: 0.5% 1%;
      }

      .matchup-card {
        .match-card-body .head .head-gap {
          gap: 5px;

          .coin-name-xs {
            display: none;
          }

          img {
            height: 25px;
            width: 25px;
          }

          h4 {
            font-size: 13px;
          }

          .card-chip {
            padding: 1px 2px;

            span {
              font-size: 10px;
            }
          }
        }

        .mb-2 {
          margin-bottom: 16px;
        }

        .data-cont {
          flex-direction: column;
        }
      }

      .game-container-box {
        background-repeat: no-repeat;
        background: linear-gradient(0deg, #202123, #202123),
          linear-gradient(48.18deg, rgba(0, 0, 0, 0.04) -5.56%, rgba(255, 255, 255, 0.04) 100%);
        border: 1px solid #2e2e2e;
        box-shadow: -30px 18px 44px 0px #00000059;
        border-radius: 10px;
      }

      .vote-coin-container {
        row-gap: 100px;

        .meme-coin {
          background: none;
          box-shadow: none;
          &::after {
            background-image: none !important;
            background: none !important;
            height: 100%;
            width: 100%;
          }
        }

        .pick-button {
          padding: 15px 10px;
          margin-top: 10px;

          .pick-winner-button-icon {
            height: 15px;
            width: 15px;
            display: none;
          }
        }

        .game-coin-0 {
          border-right: 1px solid rgba(133, 133, 133, 0.199);
          padding: 10px;
        }

        .picked {
          border: 1px solid var(--primary-color) !important;
          padding: 10px;
          border-radius: 10px;
        }

        .game-coin-1 {
          border-left: 1px solid rgba(128, 126, 126, 0.11);
          padding: 10px;
        }
      }

      .vote-coin-header {
        flex-direction: row;

        .countdown {
          .text {
            display: none;
          }

          span {
            font-size: 17px;
          }
        }

        .price-badge {
          visibility: hidden;
          display: none;
          padding: 2px 10px;

          span {
            font-size: 15px;
          }
        }

        .price-badge-mobile {
          padding: 2px 0 2px 5px;
          visibility: visible;
          display: block;

          span {
            font-size: 15px;
          }

          .potential-points {
            display: flex;
            border-radius: 8px;
            background: $background-gradient;

            .left,
            .right {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 3px 6px;

              span {
                font-weight: 500;
                font-size: 15px;
              }
            }

            .right span {
              color: #643912;
              font-size: 15px;
            }

            .left {
              flex: 1;
              border-radius: 0px 6px 6px 0px;
              background-color: #28292b;
            }
          }
        }
      }

      .vs-image {
        top: calc(13% + 80px);
        width: 80px;
        height: 80px;
      }

      .today-extra {
        min-width: 200px;
        padding: 5px;
        margin-top: 20px;

        .left {
          span {
            font-size: 15px !important;
          }
        }

        .right {
          span {
            font-size: 15px !important;
          }
        }
      }

      .meme-coin .head {
        overflow: visible;
        margin-top: 6%;

        .head-gap {
          flex-direction: row;
          margin-top: -20px;

          img {
            visibility: hidden;
            display: none;
          }

          .price-container {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            gap: 5px;
          }
        }
      }

      .layout-card .bordered-cont h3 {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .meme-coin .open-chart span {
        font-size: 17px;
        text-wrap: nowrap;
      }

      .meme-coin .open-chart {
        bottom: 0;
      }

      .meme-coin .picked-cont {
        bottom: -10%;
        right: 0;
        left: 55px;
        width: fit-content;

        span {
          font-size: 12px;
        }
      }

      .meme-coin .card-head h3 {
        margin-left: 10px;
        text-align: left;
        white-space: wrap;
      }

      .meme-coin .card-head {
        overflow: visible;
      }
    }

    .game-header {
      flex-direction: column-reverse;

      .tabs .tab {
        padding: 10px 15px;
      }
    }

    .meme-coins {
      .today-extra {
        .potential-points {
          visibility: hidden;
          display: none;
        }

        .potential-points-mobile {
          visibility: visible;
          display: block;
          flex-basis: 30%;
          justify-content: start;
          align-items: center;
          align-content: center;
        }

        .coins-chart {
          flex-basis: 70%;
          height: 110px !important;
        }
      }
    }

    .meme-coins .meme-coin .banner-cont {
      height: 70px;
      margin-bottom: 20px;

      .banner {
        height: 70px;
      }
    }

    .meme-coins .meme-coin .head .head-gap .price-container {
      flex-direction: column;
    }
  }
}
