#withdrawType {
  > label span {
    font-weight: 600;
  }
}

.wallet-token-logo {
  border-radius: 50%;
  display: block;
  height: 32px;
  overflow: hidden;
  width: 32px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.wallet-transactions-history {
  span {
    &.deducted {
      color: #e83c3c;
    }
    &.receive {
      color: #25da52;
    }
  }
}

.ambassador-tabs {
  padding-right: 0 !important;
  margin-top: 48px;
  
  

  @media screen and (max-width: 578px) {
    padding: 16px 0 0 16px !important;
  }
  
  &.noPadding {
    @media screen and (max-width: 578px) {
      padding: 0 !important;
    }
  }

  &-earnings,
  &-transaction {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%);
    border-radius: 0 20px 20px 20px;
    padding: 32px;

    @media screen and (max-width: 578px) {
      border-radius: 0 0 20px 20px;
      padding: 24px;
    }

    .MuiBox-root {
      padding: 0;
    }

    .card-container {
      .layout-card {
        margin: 0;
        border-radius: 0 0 20px 20px;
      }
    }
  }

  &-header {
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-flexContainer {
      button {
        background: black;
        border-radius: 20px 20px 0 0;
        line-height: 100%;
        padding: 22px 30px;

        &[aria-selected="true"] {
          background: linear-gradient(90deg,
              rgba(0, 0, 0, 0.04) 0%,
              rgba(255, 255, 255, 0.04) 100%),
            #141518;
        }
      }
    }
  }

  .wallet-earnings-results {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    margin-bottom: 2rem;
    
    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-gap: 24px;
    }

    .result {
      position: relative;
    }

    .icon {
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      z-index: 1;

      @media screen and (max-width: 992px) {
        top: unset;
        bottom: -24px;
        right: 50%;
        transform: translateX(50%);
      }

      &--equal {
        transform: translateY(-50%) rotate(90deg);

        @media screen and (max-width: 992px) {
          transform: translateX(50%) rotate(90deg);
        }
      }
    }
  }
}
