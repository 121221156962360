.radio-group-cards {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @media ($mobile-media) {
    grid-template-columns: 1fr;
  }

  .MuiFormControlLabel-root {
    padding: 20px 30px;
    border: 1px solid rgba($color: white, $alpha: 0.15);
    border-radius: 8px;
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: row-reverse;
    position: relative;

    .MuiRadio-root {
      position: absolute;
      top: 0px;
      right: 0px;
      justify-content: flex-start;
    }

    .MuiTypography-root {
      flex: 1;
    }

    .radio-label {
      h5 {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
      }

      .flex {
        gap: 14px;
        span {
          font-size: 21px;
          line-height: 21px;

          &.small {
            font-size: 15px;
            line-height: 15px;
          }
        }
      }
    }
  }

  .checked {
    border: 1px solid $primary-blue;
    
    h5,
    span {
      @include text-background-gradient;
    }
  }
}
