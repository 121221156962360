.faq-page {
  display: flex;

  &-container {
    border-radius: 24px;
    padding: 28px 24px;

    @media screen and (max-width: 578px) {
      border-radius: 18px;
      padding: 18px 16px;
    }
  }

  .faq-button {
    margin-top: 0 !important;
    padding: 12px 20px !important;

    @media screen and (max-width: 578px) {
      justify-content: center;
      padding: 12px !important;
      width: 100% !important;

      .icon-cont {
        margin-right: 8px;
      }
    }
  }

  .faq-list {
    .card-main {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 578px) {
        padding: 24px 0;
      }
    }
  }
}

.faq-accordion {
  .MuiButtonBase-root {
    border-radius: 8px;

    svg {
      stroke-width: 1px;
      stroke: #2c2e2f;
      height: 3.5rem;
      width: 3.5rem;
    }
  }
}
