.leaderboard-page {
  display: flex;
  gap: 2rem;
  position: relative;
  max-width: 100%;
  padding-top: 4rem;

  @media ($tablet-media) {
    flex-wrap: wrap;
  }

  @media ($mobile-media) {
    flex-direction: column;
    padding-top: 2rem;
  }

  .top-container {
    border-radius: 8px;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #141518;
    box-shadow: 0px 60px 120px 0px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    max-width: 100%;

    @media ($mobile-media) {
      padding: 2rem 1rem;
    }
  }
}

.leaderboard-memecoin-logo {
  position: relative;
  display: block;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  
  img {
    border-radius: 50%;
    display: block;
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
  }
}