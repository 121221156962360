.multiplier-modal {
  display: flex;
  gap: 80px;

  .left-content {
    width: 200px;
    overflow: visible;

    @media ($mobile-media) {
      display: none;
    }

    .left-content-img {
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      width: 240px;
      background-size: cover;
    }
  }

  .right-content {
    flex: 1;

    h2 {
      font-size: 26px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      opacity: 0.7;
    }
  }
}
