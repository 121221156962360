.taxes-page {
  .btn-link {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    font-weight: 600;
    justify-content: center;
    margin: 0;
    padding: 0;

    &:hover {
      background: none;
      color: $primary-blue !important;
      -webkit-text-fill-color: unset;
    }
  }

  .form {
    #formType {
      flex-direction: row;
      gap: 1rem;
    }
  }
}
