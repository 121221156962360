.game-results-page {
  .text-sol {
    color: var(--primary-color);
  }

  .show-single-game-button {
    display: flex;
    gap: 6px;
    background: none;
    outline: none;
    border-radius: 8px;
    border: 1px solid $primary-blue;
    padding: 4px 8px;
    color: white;
    cursor: pointer;

    &:hover {
      background: rgba($primary-blue, 0.2);
    }
  }

  .game-single-result {
    scroll-margin-top: 20px;

    &.d-none {
      display: none;
    }

    .card-main {
      @media screen and (max-width: 768px) {
        padding: 40px 0;
      }
    }
  }

  .result-match {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: center;
    padding-top: 16px;
    
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 4px;
      padding-top: 32px;
    }

    > span {
      font-weight: 700;
      font-size: 22px;
    }

    &-one {
      justify-content: flex-end;
    }

    > div {
      align-items: center;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      gap: 4px;
      width: 100%;

      &.winner {
        color: $primary-blue;
      }
      
      @media screen and (max-width: 768px) {
        justify-content: center;
      }

      picture {
        border-radius: 50%;
        display: block;
        height: 32px;
        width: 32px;
        
        img {
          border-radius: 50%;
          object-fit: cover;
          object-position: center center;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .reset-date-button {
    align-items: center;
    background: none;
    border-radius: 50%;
    border: 1px solid #dd4242;
    color: #dd4242;
    cursor: pointer;
    display: inline-flex;
    font-size: 10px;
    height: 16px;
    justify-content: center;
    line-height: 100%;
    padding: 0;
    position: relative;
    top: -2px;
    right: -4px;
    width: 16px;
  }
}