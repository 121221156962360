.btn {
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  position: relative;
  border: none;

  .w-fit {
    width: fit-content;
  }

  &.normal {
    border-radius: 8px;
  }

  &.rounded {
    border-radius: 99px;
  }

  &.sm {
    padding: 6px 16px;
    font-size: 12px;
    height: fit-content;
    width: fit-content;
  }

  &.xsm {
    padding: 0px;
    font-size: 12px;
    height: fit-content;
    width: fit-content;
  }

  &.md {
    padding: 18px;
    font-size: 16px;
    max-height: 52px;
    font-size: 18.25px;
    line-height: 17px;
  }

  .icon-cont {
    position: absolute;
    height: 100%;
    left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0px;
  }

  &.default {
    background: $background-gradient;
    background-blend-mode: overlay, normal;
    color: var(--black);
  }

  &.default:hover {
    background: linear-gradient(40deg, $primary-blue 40%, $primary-purple);
    background-blend-mode: overlay, normal;
    color: var(--black);
  }

  &.bordered {
    border: 1.5px solid var(--primary-color);
    background: none;
    @include text-background-gradient;
  }
  &.text {
    border: 0;
    background: none;
    @include text-background-gradient;
  }
  &.btn-link {
    border: 0px;
    text-decoration: underline;
  }

  &.bordered-nb {
    background: none;
  }

  &.full-width {
    width: 100%;
  }

  &.brown {
    background: linear-gradient(266deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #453628;
    background-blend-mode: soft-light, normal;
    color: var(--white);
  }

  &.dark-red {
    background: linear-gradient(266deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #4d2626;
    background-blend-mode: soft-light, normal;
    color: var(--white);
  }

  &.purple {
    background: linear-gradient(266deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #3b264d;
    background-blend-mode: soft-light, normal;
    color: var(--white);
  }

  &.disabled {
    opacity: 75%;
    cursor: not-allowed;

    .flex-column {
      opacity: 1;
    }
  }

  &.gray-btn {
    background: gray;
    color: white;

    &:hover {
      color: white;
      background: gray;
    }
  }
}
