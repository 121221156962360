html,
body,
div,
span,
applet,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Montserrat";
}

html {
  height: 100%;
  font-size: 16px;
}

input,
button {
  font-family: "Montserrat";
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  height: 100%;
  overflow-x: hidden;
  background: linear-gradient(0deg, #0e0f12, #0e0f12),
    linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%);
}

#root {
  height: 100%;

  @media ($mobile-media) {
    max-height: 100dvh;
  }
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

p {
  font-size: $paragraph-size;

  &.small {
    font-size: $paragraph-small-size;
  }
}

h1 {
  font-size: $header-1-size;
}

h2 {
  font-size: $header-2-size !important;
  font-weight: 600 !important;
}

h3 {
  font-size: $header-3-size;
}

h4 {
  font-size: $header-4-size;
}

@media (max-width: 1500px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}

@media print {}

@page {}
