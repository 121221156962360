.podcast-button {
  border: none;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  overflow: hidden;
  padding: 13px 28px !important;
  text-align: left;

  .icon-cont {
    left: 0;
    margin-right: 19px;
    position: relative;
    height: unset;
  }

  > p {
    font-size: 16px;
    font-weight: 600;
    line-height: unset;
    margin-left: 0;
    text-transform: unset;
    line-height: 120%;
  }
  
  @media screen and (max-width: 578px) {
    padding: 10px 16px !important;

    > p {
      margin: 0;
      font-size: 14px !important;
    }
  }

  .audio-progress {
    background-color: $primary-purple;
    bottom: 0;
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    transition: width 0.2s ease;
  }
}
