.table-cards {
  border-radius: 8px;
  box-shadow: 0px 60px 120px 0px #000000e5;

  .table-card {
    gap: 24px 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 14px;
    border-bottom: 1px solid rgba($color: white, $alpha: 0.1);

    &:last-of-type {
      border-bottom: 0px;
    }

    .table-card-row {
      display: flex;
      flex: 1;
      flex-direction: column;

      label,
      .value {
        white-space: nowrap;
      }

      label {
        text-transform: uppercase;
        font-size: 8px;
        color: rgba($color: white, $alpha: 0.7);
        letter-spacing: 10%;
      }

      .value {
        font-size: 14px;
      }
    }
  }

  .placeholder {
    padding: 51px 20px;
    text-align: center;
    box-shadow: 0px 60px 120px 0px #000000e5;

    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba($color: white, $alpha: 0.5);
    }
  }
}

.MuiDataGrid-main {
  .MuiDataGrid-columnHeader {
    text-transform: uppercase;
  }

  .MuiDataGrid-overlayWrapper {
    height: 100px;
  }
}
