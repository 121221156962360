.card-container {
  height: auto;
  display: flex;
  box-shadow: 0px 60px 120px 0px #00000026;
  flex-direction: column;

  .layout-card {
    border-radius: 20px;
    position: relative;
    background-color: #111215;
    box-shadow: unset !important;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%);
      border-radius: 20px;
      z-index: 0;
    }

    .card-main {
      position: relative;
      z-index: 1;
    }
  }

  &.centered-card {
    .card-main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.overflow-auto {
    overflow: auto;
  }
}
