.comp-plan {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    border-bottom: 1px solid rgba($color: white, $alpha: 0.5);

    &:last-of-type {
      border-bottom: 0px;
    }

    .col {
      flex: 1;
      display: flex;
      gap: 30px;
      padding: 16px;

      &.br {
        border-right: 1px solid rgba($color: white, $alpha: 0.5);
      }

      .infos {
        flex: 1;
        display: flex;

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 14px;

            &:last-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;

        h3 {
          font-size: 25px;
          font-weight: bold;
          @include text-background-gradient;
        }
      }

      &.big {
        .title {
          width: unset;
        }

        .infos {
          justify-content: space-around;

          p {
            font-size: 20px;
          }
        }

        .title h3 {
          font-size: 40px;
        }
      }

      &.last {
        .title {
          width: 28%;
        }
      }

      .row-footer .primary {
        @include text-background-gradient;
      }
    }
  }

  @media ($tablet-media) {
    .row {
      flex-direction: column;
    }

    .col {
      gap: 16px !important;
      flex-direction: column;

      .title {
        text-align: center;
        width: 100% !important;
      }

      &.br {
        border-right: 0px !important;
        border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
      }

      .infos {
        justify-content: center;
        gap: 16px;
        flex-direction: column;
        text-align: center;

        .info {
          flex: unset !important;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
