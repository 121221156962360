.genealogy-teams {
  .row-items {
    gap: 16px;
  }

  .card {
    min-width: 260px;
    padding: 12px;
    box-shadow: 0px 60px 120px 0px rgba(0, 0, 0, 0.1490196078);
    border-radius: 8px;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #141518;
    // width: 100%;

    &.dark {
      background: unset;
      background-color: #121316;
    }

    .border-bottom {
      border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
      margin: 7px 0;
    }

    p {
      font-size: 14px;
    }

    .bold {
      font-weight: bold;
    }

    .MuiLinearProgress-root {
      margin-left: 8px;
    }

    .peronalAmount {
      font-size: 16px;
      margin-top: 8px;
      font-weight: 600;
    }

    .green {
      color: #33d342;
    }

    .white {
      color: white;
    }
  }

  @media ($mobile-media) {
    .row-items {
      flex-wrap: wrap;
    }
  }
}
