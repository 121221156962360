.profile {
  display: flex;
  gap: 35px;
  margin-bottom: 35px;

  .profile-personal-info-form {
    .label {
      min-width: 150px;
    }
  }

  @media screen and (max-width: 991px) {
    flex-direction: column !important;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row !important;
  }

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @import './components/Welcome/style';
}
