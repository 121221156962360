.coin-packs-card {
  padding: 16px;
  position: relative;
  max-width: 100%;

  .table-cont {
    max-width: 100%;
  }

  .packs-head {
    text-align: center;

    h1 {
      font-size: 26px;
    }

    span {
      font-size: 14px;
    }
  }

  .packs-filters {
    margin-bottom: 40px;
  }

  .mobile {
    display: none;
  }

  @media ($mobile-media) {
    padding: 0;

    .packs-filters {
      flex-direction: column-reverse;
    }

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .packs-filters {
      .flex {
        flex-wrap: wrap;
        gap: 8px 0px;
        margin-top: 14px;
        justify-content: center;
      }
    }
  }
}
