#game-history-page {
  max-width: 1152px;
  margin: 0 auto;

  .pagination-container {
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .game-history {
    &-panel {
      background: linear-gradient(45deg,
          rgba(0, 0, 0, 0.04) 0%,
          rgba(255, 255, 255, 0.04) 100%),
        #141518;
      border-radius: 8px;
    }

    &-filters {
      color: white;
      background: rgba(#d9d9d9, 0.03);
      border-radius: 8px;
      padding: 16px 20px;

      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        padding: 16px 18px;

        .flex {
          width: 100%;

          b {
            min-width: 115px;
          }

          .form-input {
            width: 100%;
          }
        }
      }
    }

    &-list {
      padding: 0 20px 40px;

      @media screen and (max-width: 768px) {
        padding: 0 18px 18px;
      }

      @media screen and (max-width: 578px) {
        padding: 18px 0;
      }
    }
  }
}

.game-group {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-header {
    border-radius: 20px;
    padding: 16px 0;
  }
}

.game-container {
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 0.04) 100%),
    #1b1c1f;
  border-radius: 8px;
  box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px !important;

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      justify-content: flex-start;

      @media screen and (max-width: 768px) {
        justify-content: center;
        width: 100%;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      width: 60%;

      &.empty {
        justify-content: flex-end;
      }

      @media screen and (max-width: 992px) {
        display: none;
      }

      .text-number {
        align-items: center;
        display: flex;
        font-size: 10px;
        font-weight: 400;
        line-height: 100%;
        padding: 0 24px;

        @media screen and (max-width: 768px) {
          gap: 8px;
          justify-content: flex-start;
          padding-left: 0;
          text-align: left;
        }

        > * {
          &:first-child {
            max-width: 70px;

            @media screen and (max-width: 768px) {
              max-width: fit-content;
            }
          }
        }

        .text-sol {
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 100%;
          letter-spacing: 1px;
          text-transform: uppercase;
          white-space: nowrap;
          @include text-background-gradient;
          display: block;
          // padding-right: 45px;

          @media screen and (max-width: 768px) {
            text-align: left;
            font-size: 12px;
          }
        }

        .text-red {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 100%;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #bd3045;
          white-space: nowrap;

          @media screen and (max-width: 768px) {
            text-align: left;
            font-size: 12px;
          }
        }
      }
    }

    .controls {
      display: flex;
      gap: 8px;
      align-items: center;

      .btn {
        display: flex;
        align-items: center;
        height: 48px;
        width: 56px;

        &.default {
          border: 1.5px solid var(--primary-color);
        }
      }
    }

    @media screen and (max-width: 578px) {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &-body {
    width: 100%;
  }

  .games {
    gap: 24px;
    display: flex;
    width: 100%;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    &.hide-redeem {
      .redeem-container-button {
        display: none;
      }
    }
  }

  .game {
    background: linear-gradient(45deg,
        rgba(0, 0, 0, 0.04) 0%,
        rgba(255, 255, 255, 0.04) 100%),
      #222325;
    box-shadow: -30px 60px 120px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    padding: 0 12px 12px;
    position: relative;
    width: 100%;

    * {
      z-index: 2;
    }

    &.winner {
      &::after {
        content: "";
        border-radius: 18px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $background-gradient;
      }

      &::before {
        content: "";
        background-blend-mode: overlay;
        background: linear-gradient(45deg,
            rgba(0, 0, 0, 0.04) 0%,
            rgba(255, 255, 255, 0.04) 100%),
          #1b1c1e;
        border-radius: 18px;
        height: calc(100% - 10px);
        left: 5px;
        position: absolute;
        top: 5px;
        width: calc(100% - 10px);
        z-index: 2;
      }

      .picked {
        background: $background-gradient;
        border-radius: 0px 0px 4px 4px;
        color: black;
      }

      .results {
        visibility: visible;

        @media screen and (min-width: 992px) {
          display: none;
        }
      }

      .winner-icon {
        position: absolute;
        top: 12px;
        left: 12px;

        @media screen and (min-width: 993px) {
          height: 45px;
          width: 45px;
          top: unset;
          left: unset;
          bottom: 12px;
          right: 12px;
        }
      }
    }

    &-header {
      display: flex;
      height: 20px;
      justify-content: center;

      .picked {
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0px 0px 4px 4px;
        display: flex;
        font-size: 11px;
        line-height: 100%;
        padding: 0 14px;
        padding: 4px 10px 3px;
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      gap: 44px;
      padding: 18px 0;

      @media screen and (max-width: 578px) {
        padding: 18px;
      }

      &-icon {
        gap: 24px;

        @media screen and (max-width: 992px) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 50%;
        }

        .img {
          align-items: center;
          background-color: rgba(255, 255, 255, 0.25);
          border-radius: 50%;
          display: flex;
          height: 65px;
          justify-content: center;
          min-height: 65px;
          min-width: 65px;
          overflow: hidden;
          width: 65px;

          img {
            object-fit: cover;
            object-position: center center;
            max-height: 100%;
            max-width: 100%;
          }

          @media screen and (max-width: 578px) {
            margin: 0 auto;
            min-height: 80px;
            min-width: 80px;
            height: 80px;
            width: 80px;

            img {
              width: 80px;
            }
          }
        }

        .results {
          visibility: hidden;

          @media screen and (min-width: 992px) {
            display: none;
          }
        }

        .more-info {
          button {
            align-items: center;
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            gap: 2px;
            outline: none;
            padding-top: 8px;

            span {
              @include text-background-gradient;
            }
          }

          &:hover {
            button {
              color: $primary-blue;
            }
          }
        }
      }

      &-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6px 44px;

        @media screen and (max-width: 992px) {
          grid-template-columns: 1fr;
          width: 50%;
        }

        .text {
          font-size: 12px;
          max-width: 100px;
          width: 100%;

          @media screen and (min-width: 1200px) {
            text-wrap: nowrap;
          }

          @media screen and (max-width: 578px) {
            width: calc(50% - 5px);
          }

          @media screen and (max-width: 500px) {
            width: 100%;
          }

          p {
            white-space: normal;
          }
        }
      }
    }

    &-footer {
      display: none;

      @media screen and (max-width: 992px) {
        display: flex;
      }

      @media screen and (max-width: 578px) {
        padding: 0 18px 8px;
      }

      .btn {
        background: $background-gradient;
        color: black;
        width: 100%;
      }
    }
  }
}

.card-chip {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px 6px;
  border-radius: 99px;

  &.with-bg {
    background: #b2fe664d;
    border: 0px;

    &.not-growing {
      background: #fe71664d;
    }
  }

  &.with-border {
    border: 1.5px solid #b2fe664d;

    &.not-growing {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  span {
    font-size: 12px;
    font-weight: 500;
  }

  .chip-bubble {
    width: 11px;
    height: 11px;
    background: #deff5c;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.not-growing-bubble {
      background-color: #ff665c;
    }

    svg {
      z-index: 2;
    }

    &::before {
      border-radius: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(255, 255, 255, 0.3) 100%);
      z-index: 1;
    }
  }
}

.ai-data {
  margin-top: 16px;

  p {
    font-size: 16px;
  }

  .categories {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 4px;

    .category {
      padding: 0px 8px;
      background-color: rgba($color: $primary-blue, $alpha: 0.3);
      border-radius: 8px;

      span {
        font-size: 13px;
      }
    }
  }
}
