.memegamer-rules-page {
  margin-bottom: 50px;
  p {
    color: white;
    font-family: Montserrat;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  li {
    margin-bottom: 8px;
    font-size: 1rem;
  }

  @media screen and (min-width: '768px') {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  h1 {
    @include text-background-gradient;
  }
}

.dashboard.memegamer-rules {
  .memegamer-rules-page {
    margin-top: 20px;

    p, li {
      font-weight: 300;
      letter-spacing: 0.2px;
      line-height: 1.5;
    }
  }
}