.qr-code-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999;
  padding-bottom: 10vh;

  .backdrop {
    background-color: rgba(black, 0.1);
    backdrop-filter: blur(1.25px);
    position: absolute;
    display: block;
    height: 100vh;
    width: 100vw;
    z-index: 0;
  }
  .container {
    align-items: center;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%),#141518;
    border-radius: 16px;
    box-shadow: 0 0 25px 10px rgba(black, 0.15);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 24px;
    max-height: 550px;
    max-width: 650px;
    position: relative;
    width: 100%;
    z-index: 55;

    .qr-close-button {
      border: none;
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .qr-download-button {
      font-size: 16px;
      margin: 24px 0 32px;
      padding: 10px;
      width: 400px;
    }
  }
}