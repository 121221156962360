.renew-passes {
  display: flex;

  .activate-cont {
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid rgba($color: white, $alpha: 0.1);
  }

  h2 {
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 600;
  }

  .icon-cont {
    margin-left: 4px;
    margin-top: -8px;
  }

  .activate-coin-text {
    max-width: 90px;
    margin-right: 20px;
  }

  .purchase-coin-text {
    max-width: 94px;
    margin-right: 16px;
  }

  .purchase-btn {
    width: 174px;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    color: rgba($color: white, $alpha: 0.5);
    max-width: 125px;
    line-height: 15px;
    margin-right: 4px;
  }

  @media ($mobile-media) {
    flex-direction: column;

    .purchase-cont {
      flex-direction: column;

      .purchase-coin-text {
        margin-bottom: 9px;
        text-align: center;
        max-width: unset;
      }
    }

    .activate-cont {
      flex-direction: column;
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
      border-bottom: 1px solid rgba($color: white, $alpha: 0.1);
      padding-bottom: 24px;
      margin-bottom: 24px;

      .activate-coin-text {
        margin-bottom: 9px;
      }

      h5 {
        margin-bottom: 20px;
      }

      .activate-coin-text,
      h5 {
        text-align: center;
        max-width: unset;
      }
    }
  }
}
