.my-coin-picks-grid-view {
  .table {
    .first-column {
      color: var(--primary-color);
    }

    .coin-name {
      font-weight: 600;
      line-height: normal;
      padding: 11px;
    }

    .coin-price {
      font-size: 0.9rem;
    }

    .coin-image {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}
