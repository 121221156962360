.meme-coins-page-modal {
  .max-width-cont {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .label {
    font-size: 12px;
    opacity: 0.7;
  }

  .coin-info {
    display: flex;
    gap: 28px;
    margin-bottom: 34px;

    @media ($mobile-media) {
      flex-direction: column;

      .flex {
        justify-content: center;
      }
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 100px;
    }
  }

  .slider-thumb {
    width: 20px;
    height: 20px;
    background: var(--primary-color);
    border-radius: 100px;
    overflow: hidden;
    position: relative;

    .layer-a {
      position: absolute;
      width: 60%;
      height: 60%;
      left: 20%;
      top: 20%;
      background-color: var(--primary-color);
      border-radius: 100%;
      z-index: 1;
      overflow: hidden;
      transform: rotate(-180deg);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(45deg, rgba($color: #000000, $alpha: 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
    }
  }

  .slider-range strong {
    font-size: 12px;
  }

  .modal-footer {
    margin-top: 43px;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
}
