.notify-row {
  padding: 24px 0;
  border-bottom: 1px solid rgba(white, 0.1);

  &:first-of-type {
    padding-top: 0;
  }

  @media screen and (max-width: 900px) {
    gap: 16px;
  }

  .title b {
    font-size: 18px;
    margin-right: 12px;
    @include text-background-gradient;
  }
}