.today-extra {
  justify-content: flex-start;

  .data {
    display: flex;
    flex-direction: column;

    .row-item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 20px 0;
      border-bottom: 1px solid rgba($color: white, $alpha: 0.1);
      flex-direction: column;

      &:last-child {
        border-bottom: 0px;
      }

      .border-bottom {
        border-bottom: 1px solid rgba($color: white, $alpha: 0.1) !important;
      }

      span {
        font-size: 17px;
        font-weight: 500;
      }
    }

    .lead-row-item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 20px 0;
      border-bottom: 1px solid rgba($color: white, $alpha: 0.1);
      margin-top: 10px;
      flex-direction: column;

      span {
        font-size: 17px;
        font-weight: 500;
      }
    }

    .row-item,
    .lead-row-item {
      .item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &.hidden {
          display: none;
        }

        &.best-change {
          position: relative;

          @media screen and (min-width: 1200px) {
            &::after {
              content: '';
              background: linear-gradient(45deg, transparent 50%, #222226 50%);
              background-blend-mode: overlay, normal;
              display: block;
              height: 60px;
              left: -80px;
              position: absolute;
              top: var(--dynamic-top);
              width: 60px;
              transform: rotate(45deg);
              border-radius: 5px;
              transition: top 0.25s ease-in-out;
              z-index: 5;
            }
          }
        }
      }

      .votes-items {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 155px;
        overflow-y: scroll;
        padding-right: 6px;
        position: relative;
        width: 100%;

        &::-webkit-scrollbar-track {
          background: rgba($primary-blue, 0.2);
        }

        &::-webkit-scrollbar-thumb {
          background: $primary-blue;
        }

        .item--sol {
          border: 1.5px solid var(--primary-color);
          padding: 10px 16px;
          border-radius: 5px;
          position: relative;
          
          &.default {
            background: $background-gradient;
            background-blend-mode: overlay, normal;
            color: var(--black);

            .card-chip {
              background-color: rgba(white, 0.2);
            }
          }
          
          &:not(.default) {
            span {
              @include text-background-gradient;
              font-size: 15px;
            }
          }
        }
      }
    }

    .leader-coin-image {
      margin-right: 5px;
      position: relative;
      top: 4px;
      border-radius: 50px;
    }
  }
}
