a, div {
  .in_focus {
    @include text-background-gradient;
    
    span {
      @include text-background-gradient;
    }
  }
}

a.out_focus {
  color: var(--white);
}

.parent:has(.child.in_focus) {
  /* Styles for the parent when a child with class 'isFocused' is present */
  /* For browsers supporting parent selectors, which is limited */
  @include text-background-gradient;
}

.gradient-divider {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255, 0.0005),
    rgb(255, 255, 255, 0.06)
  );
  height: 1px;
  display: "flex";
  width: 200px;
  margin-left: 29%;
}

.nav-list {
  // background: red;
  overflow: auto;
  height: calc(100vh - 100px);
}
