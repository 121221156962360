.user-modal {

  .section-title {
    font-weight: 600;
    font-size: 12px;

    .gold {
      color: var(--primary-color)
    }
  }

  .section-title-sol {
    font-weight: 600;
    font-size: 12px;
    color: var(--primary-color)
  }

  .user-data {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
  }

  .user-data-title {
    font-weight: 500;
    font-size: 14px;
    opacity: 50%;
    flex-basis: 50%;
  }

  .user-data-value {
    font-weight: 500;
    font-size: 14px;
    flex-basis: 50%;

  }

  .divider {
    height: 1px;
    width: 100%;
    display: flex;
    background-color: var(--white);
    opacity: 10%;
    margin-top: 15px;
    margin-bottom: 20px;
  }

}
