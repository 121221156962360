.global-notify-container {
  display: flex;

  .global-notify-item {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: 100%;

    &:not(.current) {
      display: none;
    }
  }
}