.results-box {
  align-items: center;
  background: var(--black);
  border-radius: 12px;
  min-width: 160px;
  width: 100%;

  .title {
    border-radius: 12px;
    font-weight: 700;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    span {
      @include text-background-gradient;
    }
  }

  .result {
    border-radius: 12px;
    color: black;
    font-weight: 700;
    background: $background-gradient;
    padding: 8px 0;
    text-align: center;
    width: 100%;
  }
}
