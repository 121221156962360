@import "./view-table";
@import "../../components/Tag/style";
@import "../../components/AuthLayout/style";
@import "../../components/CardLayout/style";
@import "../../components/TreeLayout/style";
@import "../../components/Button/style";
@import "../../components/Form/style";
@import "../../components/Inputs/style";
@import "../../components/AppLayout/components/CollapseIcon/style";
@import "../../components/AppLayout/components/Drawer/style";
@import "../../components/AppLayout/components/NotifyPopper/style";
@import "../../components/Table/style";
@import "../../components/Accordion/style";
@import "../../components/AppLoader/style";
@import "../../components/GenealogyTeamCards/style";
@import "../../components/RedeemButton/style";
@import "../../components/ImagePreview/style";
@import "./page-component";
@import "../../components/InfoBubbleModal/style";
@import "../../components/PlayPodcast/style";
@import "../../components/GlobalNotifyModal/style";
@import "../../components/QRCodeModal/style";
@import "../../components/ComponentLoader/style";
@import "../../features/Admin/PendingWithdrawals/style";
@import "../../features//Simulator/style";


.divider {
  height: 1px;
  width: 100%;
  display: flex;
  background-color: var(--white);
  opacity: 10%;
  margin-top: 15px;
  margin-bottom: 20px;
}


.custom-pagination {
  gap: 16px;
  @media screen and (max-width: 578px) {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }

  nav {
    ul li:first-child,
    ul li:last-child {
      button {
        border-radius: 4px;
        position: relative;
        background: transparent;

        &::after,
        &::before {
          content: "";
          background: $background-gradient;
          display: block;
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 0;
        }

        &::after {
          background: #121316;
          top: 1.5px;
          left: 1.5px;
          height: calc(100% - 4px);
          width: calc(100% - 4px);
        }

        svg {
          color: $primary-blue;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.wallet-input {
  span.flex {
    position: relative;

    &::before {
      content: '';
      background: rgba(white, 0.1);
      height: calc(100% - 24px);
      position: absolute;
      right: -14px;
      top: 12px;
      width: 1px;

      @media screen and (max-width: 578px) {
        right: -8px;
      }
    }

    @media screen and (max-width: 578px) {
      font-size: 14px;
      p {
        font-size: 14px;
      }
    }
  }

  input {
    @media screen and (max-width: 578px) {
      padding: 18px 14px;
    }
  }
}

.primary-text-gradient {
  @include text-background-gradient;
}
