.date-picker-input {
  .date-picker {
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #121316;
    transition: 0.4s background;

    fieldset {
      transition: 0.4s border-color;
      border-width: 1px !important;
    }

    &.focused {
      background-color: transparent;
    }

    .MuiInputBase-input {
      color: white;
      padding: 17.25px 29px;
      font-size: 16px;
    }

    &.sm {
      .MuiInputBase-input {
        padding: 11.25px 25px;
      }
    }

    svg {
      color: white;
    }
  }
}
