.apply-immediately {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding-top: 1.5rem;
  
  @media screen and (max-width: 578px) {
    align-items: flex-start;
  }
  
  span {
    cursor: pointer;
    line-height: 100%;
    padding: 0;
  }
}