.component-loader {
  display: inline-flex;
  z-index: 9999;

  .loading-div {

    display: flex;
    justify-content: center;
    align-items: center;

    .loading-text {
      font-size: x-large;
      @include text-background-gradient;
      opacity: 70%;
    }

    .loading-icon {
      animation: rotate 2s linear infinite;
      /* rotate animation for 2 seconds infinitely */
      font-size: 30px;
      @include text-background-gradient;
      opacity: 70%;
    }


    /* Define the animation */
    @keyframes rotate {
      from {
        transform: rotate(0deg);
        /* Start the rotation from 0 degrees */
      }

      to {
        transform: rotate(360deg);
        /* Rotate the element to 360 degrees */
      }
    }
  }
}
