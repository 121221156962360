.current-coins-list {
  flex-direction: column;
  .current-coins-list__item-wrapper{
      border-bottom: 1px solid #e5e5e536;
      padding: 15px 10px;
      position: relative;
      &:last-child{
      border-bottom: none;
    }
    }
  .current-coins-list__item{
      position: relative;
  
    img{
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 40px;
    }
  
    p{
      transition: opacity 0.3s;
      font-weight: 400;
      font-size: 16px;
    }

    p:nth-child(3){
      opacity: 0.5;
    }
    &:hover, &.active{
      cursor: pointer;
      p:nth-child(2){
          font-weight: 700;
      }
      p:nth-child(3){
        opacity: 1;
        
      }
    }
    span{
      opacity: 0;
      transition: opacity 0.3s;
    }
    &.active{
      span{
       opacity: 1;
      }
     }
  }
}
