$space-unit: 4px;
$test: (
  "ml": "margin-left",
  "mr": "margin-right",
  "mt": "margin-top",
  "mb": "margin-bottom",
  "pl": "padding-left",
  "pr": "padding-right",
  "pt": "padding-top",
  "pb": "padding-bottom",
  "p": "padding",
);

@for $i from 1 through 20 {
  @each $class, $style in $test {
    .#{$class}-#{$i} {
      #{$style}: $i * $space-unit;
    };
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.h-full {
  height: 100%;
}
